import React from "react";
import { ReplaySubject, combineLatest, of } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Divider,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import AgGridCheckboxCellRendererComponent from "../../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import AgGridRadioButtonCellRendererComponent from "../../../../shared/components/elements/agGridRadioButtonCellRendererComponent";
import AgGridEditButtonCellRendererComponent from "../../../../shared/components/elements/agGridEditButtonCellRendererComponent";

import { AuthContext } from "../../../../shared/store/authProvider";
import {
  API_ENDPOINT,
  CrudAction,
  ENTITY_FIELD_TYPE,
  ENTITY_TYPE,
  ResultStatus,
} from "../../../../shared/types/enums";
import {
  DataService,
  SubscriptionArray,
} from "../../../../shared/services/dataService";
import LookupService from "../../../../shared/services/lookupService";
import LayoutService from "../../../../shared/services/layoutService";
import DynamicControlService from "../../../../shared/services/dynamicControlService";

import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import MatThemeService from "../../../../shared/services/theme/matThemeService";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import ProfileTabService from "./profileTabService";
import ApiService from "../../../../shared/services/apiService";
import ToastService from "../../../../shared/services/toastService";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import ProfileStatusOverrideComponenet from "./profileStatusOverrideComponenet";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";
import AgGridDatePickerCellRendererComponent from "../../../../shared/components/elements/agGridDatePickerCellRendererComponent";
import AgGridDropdownWithStatusCellRenderer from "../../../../shared/components/ag-grid/agGridDropdownWithStatusCellRenderer";

class ProfileTabComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      // isReadOnly: true, not applicable here since it will be sent as a props by the parent Container
      isNew: props.inputAction === CrudAction.CREATE,
      selProfileId: !props.modalAgNode ? "" : props.modalAgNode.prid,
      // 1) source-name
      fetchProfileDataResult: ResultStatus.NOT_LOADED,
      sourceSystemList: [],

      templateFetchResult: ResultStatus.NOT_LOADED,
      allGroupFieldsMap: new Map(),
      dynamicInitialValies: {},
      dynamicControlsValidationSchema: Yup.object().shape({}),

      agGridAddressUtils: new AgGridUtil("addresS1", {
        dropDownRenderer: AgGridDropdownWithStatusCellRenderer,
        inlineEditButtonCellRendererComponent:
          AgGridEditButtonCellRendererComponent,
        isActiveCellRenderer: AgGridCheckboxCellRendererComponent,
        isPrimaryCellRenderer: AgGridRadioButtonCellRendererComponent,
      }),
      agGridContactUtils: new AgGridUtil("isactive", {
        dropDownRenderer: AgGridDropdownWithStatusCellRenderer,
        inlineEditButtonCellRendererComponent:
          AgGridEditButtonCellRendererComponent,
        isActiveCellRenderer: AgGridCheckboxCellRendererComponent,
        isPreferedCellRenderer: AgGridRadioButtonCellRendererComponent,
      }),
      agGridIdentifierUtils: new AgGridUtil("porzioGSTIdentifierTypeId", {
        inlineEditButtonCellRendererComponent:
          AgGridEditButtonCellRendererComponent,
      }),
      agGridConsentUtils: new AgGridUtil("profileConsent", {
        inlineEditButtonCellRendererComponent:
          AgGridEditButtonCellRendererComponent,
        isActiveCellRenderer: AgGridCheckboxCellRendererComponent,
        dateCellRenderer: AgGridDatePickerCellRendererComponent,
      }),
    };
  }

  componentWillUnmount() {}
  componentDidMount() {
    LookupService.fetchCommonLookups(this.context);
    this.fetchProfileData();
  }

  //#region SourceSystem DropDown

  // api
  fetchProfileData = () => {
    this.oSubscriptions.cancelAll();

    // set the loading state
    this.setState({ fetchProfileDataResult: ResultStatus.LOADING });
    // fetch-source-list
    this.oSubscriptions.add(
      combineLatest([
        LookupService.getFormattedCountriesAsOBS(this.context, null),
        LookupService.getContactTypesAsOBS(this.context), // contactTypes (TODO: HardCoded)
        LookupService.getIdentifierTypes(this.context.user.tenantId), //
        LookupService.getProfileConsentLovAsOBS(this.context.user.tenantId),
        LookupService.getConsentAsOBS(
          this.context.user.tenantId,
          this.props.modalAgNode.prid
        ), //
        // get source system list
        LookupService.getSourceSystemsByEntityAsOBS(
          this.context.user.tenantId,
          ENTITY_TYPE.PROFILE
        ),
        // for new don't get the data, set it null (addressList & contactsList are included)
        this.state.isNew
          ? of(null)
          : LookupService.getProfileTabDetailsWithErrorInfoAsOBS(
              this.context.user.tenantId,
              this.state.selProfileId,
              this.props.from
            ),
      ]).subscribe(
        // success result
        ([
          _countryList,
          _contactTypeList,
          _identifierTypeList,
          _consentLovList,
          _consentList,
          _sourceSystemList,
          _initialValuesOrNull,
        ]) => {
          // countries
          // _countryList = [{ id: 0, value: " " }, ..._countryList];
          _identifierTypeList
            .slice()
            .sort((a, b) =>
              a.lovKey > b.lovKey ? 1 : b.lovKey > a.lovKey ? -1 : 0
            );
          _consentLovList = DataService.arrayToObject(
            [{ lovId: 0, lovKey: " " }, ..._consentLovList],
            "lovId",
            "lovKey"
          );

          // 1/2) parse error fileds
          _initialValuesOrNull =
            DynamicControlService.parseErrorFields(_initialValuesOrNull);
          _initialValuesOrNull =
            DynamicControlService.parseCustomFields(_initialValuesOrNull);

          this.setState(
            {
              countryList: _countryList,
              countryCellSource: DataService.arrayToObject(
                _countryList,
                "id",
                "value"
              ),

              contactTypeList: _contactTypeList,
              contactTypeCellSource: DataService.arrayToObject(
                _contactTypeList,
                "lovId",
                "lovKey"
              ),

              porzioGstIdentifierTypeList: _identifierTypeList,
              porzioGstIdentifierTypeCellSource: DataService.arrayToObject(
                _identifierTypeList,
                "lovId",
                "lovKey"
              ),

              consentLovList: _consentLovList,

              sourceSystemList: DataService.getKeyValueCollection(
                _sourceSystemList,
                "sourceId",
                "sourceName",
                false
              ),

              initialValuesOrNull: _initialValuesOrNull,

              addressRowData: this.props.recipientProfileInfo?.addresslist
                ? this.props.recipientProfileInfo.addresslist
                : ProfileTabService.getAddressRows(_initialValuesOrNull),
              contactRowData:
                ProfileTabService.getContactRows(_initialValuesOrNull),
              identifierRowData: this.props.recipientProfileInfo?.identifierlist
              ? this.props.recipientProfileInfo.identifierlist
               : ProfileTabService.getIdentifierRows(_initialValuesOrNull),
              consentRowData: ProfileTabService.getConsentRows(_consentList),
            },
            () => {
              this.onSourceSystemChange(
                this.state.isNew ? null : _initialValuesOrNull.sourceid,
                true
              );
              this.setState({ fetchProfileDataResult: ResultStatus.LOADED });
              this.props.onProfileTabLoaded(_initialValuesOrNull);
            }
          );
        },
        // on error
        (err) => {
          ToastService.showError(`Error Occured while Loading the Profile`);
          this.setState({ fetchProfileDataResult: ResultStatus.ERROR });
        }
      )
    );
  };

  prePopulateIfAny = () => {};

  // formik
  getSourceSystemInitialValues() {
    return {
      sourceSystemId: this.state.isNew
        ? ""
        : this.state.initialValuesOrNull.sourceid,
      fileName: "",
    };
  }

  sourceSystemValidationSchema = Yup.object().shape({});
  getSourceSystemValidationSchema() {
    this.sourceSystemValidationSchema = Yup.object().shape({
      sourceSystemId: LayoutService.getNullableValidation(
        Yup.number().required("Required")
      ),
    });
    return this.sourceSystemValidationSchema;
  }

  fPropsSourceSystem = null;
  setSourceSystemFormikProps = (_fPropsSourceSystem) => {
    this.fPropsSourceSystem = _fPropsSourceSystem;
    return <></>;
  };

  // render
  TAB_PERMISSIONS = RolePermissionService.PROFILE_DETAILS;
  render() {
    const { classes } = this.props;
    // set the props for parent's validation & post logic
    this.props.tabConfig.ref = this; // 1/4) required by parent component
    this.fPropsDynamic = null;
    // trick
    this.TAB_PERMISSIONS.cannotCreateOrEdit = this.state.isNew
      ? this.TAB_PERMISSIONS.cannotCreate
      : this.TAB_PERMISSIONS.cannotEdit;

    switch (this.state.fetchProfileDataResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (
          <PageLoadingComponent
            small
            classes={classes}
            label="Loading Profile Details"
          />
        );
      case ResultStatus.SAVING:
        return (
          <PageLoadingComponent
            small
            classes={classes}
            label="Saving Profile Details"
          />
        );
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
        return (
          <>
            {/* SourceSystem  Dropdown*/}
            <Formik
              initialValues={this.getSourceSystemInitialValues()}
              validationSchema={this.getSourceSystemValidationSchema()}
              validationSchemaOptions={{ showMultipleFieldErrors: true }}
            >
              {(fPropsSourceSystem) => (
                <form>
                  <Box
                    style={{
                      paddingLeft: 24,
                      paddingRight: 16,
                      paddingTop: 8,
                      paddingBottom: 8,
                      backgroundColor: MatThemeService.getControllerBG(),
                    }}
                  >
                    {this.setSourceSystemFormikProps(fPropsSourceSystem)}
                    {this.props.inputAction === CrudAction.UPDATE ? (
                      this.state.initialValuesOrNull
                        ?.isrecipientmatch ? null : (
                        <Typography
                          style={{
                            paddingLeft: 8,
                            paddingRight: 8,
                            backgroundColor: "#FF8A8A",
                            color: "#6E0101",
                          }}
                          variant="h6"
                          align="center"
                        >
                          Unmatched Recipient
                        </Typography>
                      )
                    ) : (
                      <></>
                    )}
                    {LayoutService.getDropDown(
                      this.props.isReadOnly || !this.state.isNew,
                      classes.dialogControl,
                      classes.menuPaper,
                      fPropsSourceSystem,
                      this.sourceSystemValidationSchema,
                      "sourceSystemId",
                      "Source System",
                      this.state.sourceSystemList,
                      "id",
                      "text",
                      null,
                      true,
                      "48%",
                      (_formikProps, _newSourceSystemId) => {
                        this.onSourceSystemChange(_newSourceSystemId, false);
                      }
                    )}
                    {LayoutService.getTextBox(
                      true,
                      classes.dialogControl,
                      fPropsSourceSystem,
                      this.sourceSystemValidationSchema,
                      "fileName",
                      "File Name",
                      "string",
                      "48%"
                    )}
                  </Box>
                </form>
              )}
            </Formik>
            {/* Render Dynamic Controls */}
            {this.renderDynamicControls(classes)}
            {<Box style={{ paddingTop: 12, paddingBottom: 12 }} />}
          </>
        );
      case ResultStatus.ERROR:
      default:
        return (
          <DialogErrorFragmentComponent
            classes={classes}
            description="Error Loading Profile Details"
            onRetry={() => {
              this.fetchProfileData();
            }}
          />
        );
    }
  }

  //#endregion

  //#region Dynamic controls

  // api
  onSourceSystemChange = (_newSourceSystemId, _force) => {
    this.oSubscriptions.cancelAll();

    if (_force || this.state.selSourceSystemId !== _newSourceSystemId) {
      if (DataService.hasValidValue(_newSourceSystemId)) {
        //CLEAR CAHCE etc,...
        ProfileTabService.INIT(this.state.isNew);
        DynamicControlService.CLEAR();
        this._setTemplateFetchState(
          ResultStatus.LOADING,
          _newSourceSystemId,
          null,
          () => {
            this.oSubscriptions.add(
              LookupService.getTemplateBySourceIdAsOBS(
                this.context.user.tenantId,
                _newSourceSystemId
              ).subscribe(
                // success result
                (_templateData) => {
                  // get grouped filed configs
                  const groupFieldsMap = ProfileTabService.getGroupFieldsMap(
                    this,
                    this.state.isNew,
                    _templateData,
                    "grouprenderid",
                    "fieldID"
                  );
                  this._setTemplateFetchState(
                    ResultStatus.LOADED,
                    _newSourceSystemId,
                    groupFieldsMap,
                    () => {
                      // ToastService.showInfo(`${this.state.isNew ? "Template" : "Data"} Loaded`, 1000);
                    }
                  );
                },
                // on error
                (err) => {
                  ToastService.showError(
                    `Error Occured while Loading the ${
                      this.state.isNew ? "Template" : "Data"
                    }`
                  );
                  this._setTemplateFetchState(
                    ResultStatus.ERROR,
                    _newSourceSystemId,
                    null,
                    () => {}
                  );
                }
              )
            );
          }
        );
      } else {
        this._setTemplateFetchState(ResultStatus.LOADED, null, null, () => {});
      } // clear selectedSourceSystemId
    }
  };

  // render
  fPropsDynamic = null;
  renderDynamicControls = (_classes) => {
    switch (this.state.templateFetchResult) {
      case ResultStatus.NOT_LOADED:
        return <></>;
      case ResultStatus.LOADING:
        return (
          <PageLoadingComponent
            classes={_classes}
            label="Loading Dynamic Controls"
          />
        );
      // Good to Render the components
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
        if (!DataService.hasValidValue(this.state.selSourceSystemId)) {
          return (
            <DialogErrorFragmentComponent
              small
              classes={_classes}
              description="Select the Source System"
            />
          );
        } else if (DataService.mapHasNoElements(this.state.allGroupFieldsMap)) {
          return (
            <DialogErrorFragmentComponent
              small
              classes={_classes}
              description="Select a valid Source System"
              onRetry={() => {
                this.onSourceSystemChange(this.state.selSourceSystemId, true);
              }}
            />
          );
        } else {
          return (
            <Formik
              initialValues={this.state.dynamicControlInitialValues}
              validationSchema={this.state.dynamicControlsValidationSchema}
              validationSchemaOptions={{ showMultipleFieldErrors: true }}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {(_fPropsDynamic) => (
                <form>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <>
                      {/* Render Dynamic controls */}
                      {this.renderData(_classes, _fPropsDynamic)}

                      {/* Profile Status Selector Dialog */}
                      {!this.state.showProfileStatusDialog ? null : (
                        <ProfileStatusOverrideComponenet
                          id={this.props.modalAgNode.prid}
                          currentValue={
                            this.fPropsDynamic.values["profilestatusid"]
                          }
                          onClose={(_reload) => {
                            this.setState({ showProfileStatusDialog: false });
                          }}
                          refreshTab={() => {
                            if (this.props.setReadOnlyMode) {
                              this.props.setReadOnlyMode(true);
                            }
                            LookupService.fetchCommonLookups(this.context);
                            this.fetchProfileData();
                          }}
                        />
                      )}
                    </>
                  </MuiPickersUtilsProvider>
                </form>
              )}
            </Formik>
          );
        }
      // Error
      case ResultStatus.ERROR:
      default:
        return (
          <DialogErrorFragmentComponent
            classes={_classes}
            description="Error loading Dynamic Controls"
            onRetry={() => {
              this.onSourceSystemChange(this.state.selSourceSystemId, true);
            }}
          />
        );
    }
  };
  //#endregion

  renderData = (_classes, _fPropsDynamic) => {
    this.fPropsDynamic = _fPropsDynamic;

    // 2/2) Map Errors
    DynamicControlService.setErrors(
      _fPropsDynamic,
      this.state.initialValuesOrNull
    );

    var lastGroupIndex = 0;
    // filter, static & dynamic fields
    const oRET = DataService.getMapKeys(this.state.dynamicControlFieldsMap)
      .filter(
        (_mapKey) =>
          this.state.dynamicControlFieldsMap.get(_mapKey).fieldConfigs.length >
          0 // empty check
      )
      .map((_mapKey, _groupIndex) => {
        lastGroupIndex = _groupIndex;
        const groupInfo = this.state.dynamicControlFieldsMap.get(_mapKey);
        return (
          <React.Fragment key={`group${_groupIndex}`}>
            <Box
              key={`section${_groupIndex}`}
              style={{
                paddingLeft: 24,
                paddingRight: 24,
                paddingTop: 8,
                paddingBottom: 16,
                backgroundColor:
                  MatThemeService.getReverseAlternatingBG(lastGroupIndex),
              }}
            >
              {DataService.isStringNullOrEmpty(groupInfo.name) ? null : (
                <Typography
                  key={`sectionHeader${_groupIndex}`}
                  variant="h6"
                  className={_classes.sectionHeader}
                  style={{ margin: 8 }}
                >
                  {groupInfo.name}
                </Typography>
              )}
              {groupInfo.fieldConfigs.map((_fconfig, _fieldIndex) => {
                let _control = DynamicControlService.getControl(
                  this.props.isReadOnly,
                  _classes,
                  _fPropsDynamic,
                  this.state.initialValuesOrNull,
                  this.state.dynamicControlsValidationSchema,
                  _fieldIndex,
                  _fconfig
                );
                if (_fconfig.secondaryActionIcon) {
                  _control = (
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      {_control}
                      {LayoutService.getIconButton(
                        this.props.isReadOnly,
                        _fconfig.secondaryActionIcon,
                        _fconfig.secondaryActionTooltip,
                        _fconfig.onSecondaryActionClick,
                        "primary",
                        `secondaryAction${_fconfig.fieldID}`
                      )}
                    </Grid>
                  );
                }
                return _control;
              })}
            </Box>
            <Divider />
          </React.Fragment>
        );
      });

    // Address, Contacts & Identifiers Grid
    const gridGroups = [];

    // address grid visiblity
    gridGroups.push(
      this._getGroup(
        RolePermissionService.PROFILE_DETAILS_ADDRESS,
        "Addresses",
        this.state.allGroupFieldsMap.get(
          ProfileTabService.addressesFieldGroupKey
        ),
        this.state.agGridAddressUtils,
        this.state.addressRowData,
        ProfileTabService.getAddressAgGridColumnDefs,
        ProfileTabService.getAddressObject
      )
    );

    // contacts grid visiblity
    if (
      this.state.allGroupFieldsMap.get(
        ProfileTabService.contactMethodsFieldGroupKey
      ).fieldConfigs.length > 0
    ) {
      gridGroups.push(
        this._getGroup(
          RolePermissionService.PROFILE_DETAILS_CONTACT_METHOD,
          "Contact Methods",
          this.state.allGroupFieldsMap.get(
            ProfileTabService.contactMethodsFieldGroupKey
          ),
          this.state.agGridContactUtils,
          this.state.contactRowData,
          ProfileTabService.getContactsAgGridColumnDefs,
          ProfileTabService.getContactsObject
        )
      );
    }

    // identifiers grid visiblity
    if (
      this.state.allGroupFieldsMap.get(
        ProfileTabService.identifierFieldGroupKey
      ).fieldConfigs.length > 0
    ) {
      gridGroups.push(
        this._getGroup(
          RolePermissionService.PROFILE_IDENTITIFERS,
          "Ous Identifiers",
          this.state.allGroupFieldsMap.get(
            ProfileTabService.identifierFieldGroupKey
          ),
          this.state.agGridIdentifierUtils,
          this.state.identifierRowData,
          ProfileTabService.getIdentifierAgGridColumnDefs,
          ProfileTabService.getIdentifierObject
        )
      );
    }

    // Consent grid visiblity
    if (
      this.state.allGroupFieldsMap.get(ProfileTabService.consentFieldGroupKey)
        .fieldConfigs.length > 0
    ) {
      gridGroups.push(
        this._getGroup(
          RolePermissionService.PROFILE_CONSENTS,
          "Consents",
          this.state.allGroupFieldsMap.get(
            ProfileTabService.consentFieldGroupKey
          ),
          this.state.agGridConsentUtils,
          this.state.consentRowData,
          ProfileTabService.getConsentAgGridColumnDefs,
          ProfileTabService.getConsentObject
        )
      );
    }

    oRET.push(
      gridGroups.map((o, index) => {
        const cannotView = o.permissionObj.cannotView;
        const cannotEdit = o.permissionObj.cannotEdit || this.props.isReadOnly;
        const cannotCreate =
          o.permissionObj.cannotCreate || this.props.isReadOnly;
        o.gridUtils.setReadOnlyMode(cannotEdit); // 1/2) readonly mode for grid

        return (
          <React.Fragment key={o.group.name + "Fragment"}>
            <Box
              key={o.group.name + "Box"}
              style={
                cannotView
                  ? { maxHeight: 1, minHeight: 1, height: 1 } // hide
                  : {
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 8,
                      paddingBottom: 16,
                      backgroundColor: MatThemeService.getReverseAlternatingBG(
                        ++lastGroupIndex
                      ),
                    }
              }
            >
              <div id="MainRoleGrid">
                {cannotView ? null : ( // hide
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="h6"
                      className={_classes.sectionHeader}
                      style={{ margin: 8 }}
                    >
                      {o.header}
                    </Typography>
                    {LayoutService.getIconButton(
                      cannotCreate,
                      MatIconService.ADD_CIRCLE_OUTLINE,
                      "Add New " + o.group.name,
                      () => {
                        if (o.gridUtils.isNotEditing()) {
                          o.gridUtils.addNewRow(o.emptyObjectCallback());
                        }
                      }
                    )}
                  </Grid>
                )}
                <div
                  style={
                    cannotView
                      ? { height: 1, minHeight: 1, maxHeight: 1 } // hide
                      : { height: `200px`, width: `100%` }
                  }
                  {...LayoutService.getAgGridTheme()}
                >
                  <AgGridReact
                    rowData={o.rowData}
                    columnDefs={o.columnDefs}
                    frameworkComponents={o.frameworkComponents}
                    suppressClickEdit={true}
                    gridOptions={{
                      context: { componentParent: this },
                      suppressContextMenu: true,
                      ...AgGridColumnExt.getGridOptions(40),
                      ...o.inlineEditEventCallback(),
                    }}
                    onGridReady={(params) => {
                      o.setGridParamsCallback(params, false);
                      o.gridUtils.setReadOnlyMode(cannotEdit); // 2/2) readonly mode for grid
                      // sma
                    }}
                  />
                </div>
              </div>
            </Box>
          </React.Fragment>
        );
      })
    );

    // RETURN
    return oRET;
  };

  //#region  Utils
  _getGroup = (
    _permissionObj,
    _header,
    _group,
    _gridUtils,
    _rowData,
    _columnDefsCallback,
    _emptyObjectCallback
  ) => {
    const cannotDelete =
      this.TAB_PERMISSIONS.cannotEdit || _permissionObj.cannotDelete;
    const canDelete = !cannotDelete;
    return {
      header: _header,
      group: _group,
      gridUtils: _gridUtils,
      emptyObjectCallback: _emptyObjectCallback,
      rowData: _rowData,
      columnDefs: _columnDefsCallback(
        this,
        this.props.isReadOnly,
        canDelete,
        _gridUtils,
        _group.fieldConfigs
      ),
      frameworkComponents: _gridUtils.frameworkComponents,
      inlineEditEventCallback: _gridUtils.bindInlineEditEvents,
      setGridParamsCallback: _gridUtils.setGridParams,
      permissionObj: _permissionObj,
    };
  };

  _setTemplateFetchState = (
    _templateFetchResult,
    _sourceSystemId,
    _groupFieldsMap,
    _callback = () => {}
  ) => {
    const _dynamicControlFieldsMap = DataService.getMapByKeys(_groupFieldsMap, [
      ProfileTabService.filterFieldGroupKey,
      ProfileTabService.staticFieldGroupKey,
      ProfileTabService.otherFieldGroupKey,
      ProfileTabService.customFieldGroupKey,
    ]);

    // if transaction recipinet info has some data, then prepopulate them, otherwise use the initialValue if edit, or null if new
    const initialValues = this.props.recipientProfileInfo
      ? this.props.recipientProfileInfo
      : this.state.initialValuesOrNull;
    if (this.fPropsSourceSystem) {
      if (initialValues) {
        this.fPropsSourceSystem.setFieldValue(
          "fileName",
          initialValues?.filename
        );
      } else if (this.state.isNew) {
        this.fPropsSourceSystem.setFieldValue("fileName", "Manual Entry");
      }
    }

    this.setState(
      {
        selSourceSystemId: _sourceSystemId,
        templateFetchResult: _templateFetchResult,
        allGroupFieldsMap: _groupFieldsMap,
        dynamicControlFieldsMap: _dynamicControlFieldsMap,
        dynamicControlInitialValues: DynamicControlService.getIntitialValues(
          this.state.isNew,
          _dynamicControlFieldsMap,
          initialValues
        ),
        dynamicControlsValidationSchema:
          DynamicControlService.getValidationSchema(_dynamicControlFieldsMap),
      },
      _callback
    );
  };
  //#endregion

  /** 1/3 Required */
  isDirtyCallback = () => {
    // do any additional checkings if needed
    if (this.fPropsDynamic) {
      return this.fPropsDynamic.dirty;
    } else {
      return false;
    }
  };
  /** 2/3 Required in Parent */
  resetCallback = (_updateFormWithNewValues = false) => {
    // modified on 02-03-2022 as per ticket PP2-1241
    if (this.fPropsDynamic) {
      if (_updateFormWithNewValues) {
        this.fPropsDynamic.resetForm({
          values: { ...this.fPropsDynamic.values },
        });
      } else {
        this.fPropsDynamic.resetForm();
      }
    }
    // do any additional resetting if needed
  };

  /** 3/3 Required in Parent */
  postCallbackOBS = () => {
    if (
      DataService.isNullOrUndefined(this.fPropsDynamic) ||
      this.fPropsDynamic.isSubmitting
    ) {
      return of(null);
    } else {
      var oReturnSubject = new ReplaySubject(); // 1st
      var oValidationSubject = new ReplaySubject(); // 2nd

      this._validate(oValidationSubject);
      oValidationSubject.asObservable().subscribe((_dataToPost) => {
        if (_dataToPost) {
          ApiService.postOBS(
            API_ENDPOINT.CORE,
            "/Profiles/SaveProfile",
            JSON.stringify(_dataToPost)
          ).subscribe(
            (_successResult) => {
              if (_successResult.message == "Duplicate_Error") {
                oReturnSubject.next("duplicate_error");
              } else {
                ToastService.showSuccess("Profile Saved");
                oReturnSubject.next(_successResult);
              }
            },
            (_errorResult) => {
              ToastService.showError("Error occured while saving");
              oReturnSubject.next("save_error");
            }
          );
        } else {
          ToastService.showWarning("Please recheck your Input");
          oReturnSubject.next("validation_error");
        }
      });

      // return the subject as observable
      return oReturnSubject.asObservable(); // 3rd
    }
  };

  _validate = async (_oSubject) => {
    // if (!this.state.isNew) { // no validation for edit
    //     _oSubject.next(this._getDataToPost());
    // } else {
    //     await this.state.dynamicControlsValidationSchema.validate(this.fPropsDynamic.values, { abortEarly: false })
    //         .then((x) => {
    //             _oSubject.next(this._getDataToPost());
    //         })
    //         .catch((erroObj) => {
    //             if (erroObj.inner) { erroObj.inner.forEach(err => { this.fPropsDynamic.setFieldError(err.path, err.message); }); }
    //             _oSubject.next(null); // error
    //         });
    // }
    _oSubject.next(this._getDataToPost());
  };

  _getDataToPost = () => {
    //---Mandatory-Fields---------------
    var dataToPost = {};

    //---Static-Fields---------------
    const staticFieldConfigs = [
      ...this.state.dynamicControlFieldsMap.get(
        ProfileTabService.filterFieldGroupKey
      ).fieldConfigs,
      ...this.state.dynamicControlFieldsMap.get(
        ProfileTabService.staticFieldGroupKey
      ).fieldConfigs,
      ...this.state.dynamicControlFieldsMap.get(
        ProfileTabService.otherFieldGroupKey
      ).fieldConfigs,
    ];
    staticFieldConfigs.forEach((fieldConfig) => {
      const _mappedFieldName =
        DynamicControlService.getMappedFieldName(fieldConfig) + "";
      var _fieldValue = this.fPropsDynamic.values[_mappedFieldName];
      if (fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.BOOLEAN) {
        dataToPost[_mappedFieldName] = DataService.parseBoolean(_fieldValue);
      } else if (
        fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.NUMERIC ||
        fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.DECIMAL
      ) {
        dataToPost[_mappedFieldName] = DataService.getNumberOrDefault(
          _fieldValue,
          null
        );
      } else if (fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.LOV) {
        // For Lov both profilestatusId & profilestatus keys should be set

        // (enable this & disable below 1/3)
        _fieldValue = _fieldValue > 0 ? _fieldValue : null; // (0 is changed to null -> new impl for PP2-740)
        //_fieldValue = _fieldValue > 0 ? _fieldValue : 0; // if null then use the 0 based lovKey

        dataToPost[_mappedFieldName] = _fieldValue; // <field>Id eg: profilestatusId
        if (_mappedFieldName.endsWith("id")) {
          const _lovStringFieldName = _mappedFieldName.substring(
            0,
            _mappedFieldName.length - 2
          ); // <field> eg: profilestatus
          const lovObj = DataService.getFirstOrDefault(
            fieldConfig.customLOVList.filter((x) => x.lovId === _fieldValue)
          );

          // (enable this & disable below 2/3)
          dataToPost[_lovStringFieldName] = lovObj ? lovObj.lovKey : null; // ("" is changed to null -> new impl for PP2-740)
          //dataToPost[_lovStringFieldName] = lovObj ? lovObj.lovKey : "";
        }
      }
      // enable below 3/3)
      else if (fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.TEXT) {
        dataToPost[_mappedFieldName] = DataService.getStringOrDefault(
          _fieldValue,
          null
        ); // ("" is changed to null -> new impl for PP2-740)
      } else {
        dataToPost[_mappedFieldName] = _fieldValue;
      }
    });

    //---Custom-Fields---------------
    dataToPost.customfields = this.state.dynamicControlFieldsMap
      .get(ProfileTabService.customFieldGroupKey)
      .fieldConfigs.map((fieldConfig) => {
        var _fieldValue =
          this.fPropsDynamic.values[
            DynamicControlService.getMappedFieldName(fieldConfig)
          ];
        if (fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.LOV) {
          _fieldValue = _fieldValue > 0 ? _fieldValue : 0;
          const lovObj = DataService.getFirstOrDefault(
            fieldConfig.customLOVList.filter((x) => x.lovId === _fieldValue)
          );
          return {
            FIELDID: fieldConfig.fieldID,
            FIELDNAME: fieldConfig.fielD_ALIASNAME,
            FIELDVALUE: _fieldValue,
            FIELDLOVKEY: lovObj ? lovObj.lovKey : "",
          };
        } else if (
          fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.DATE_TIME ||
          fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.DATE
        ) {
          return {
            FIELDID: fieldConfig.fieldID,
            FIELDNAME: fieldConfig.fielD_ALIASNAME,
            FIELDVALUE: _fieldValue
              ? DataService.formatDate(new Date(_fieldValue))
              : null,
          };
        } else {
          return {
            FIELDID: fieldConfig.fieldID,
            FIELDNAME: fieldConfig.fielD_ALIASNAME,
            FIELDVALUE: _fieldValue,
          };
        }
      });
    dataToPost.customfields = DataService.arrayToSingleQuotedJsonString(
      dataToPost.customfields,
      ""
    );

    //---Grid---------------
    // addresses-grid
    dataToPost.addresslist = [];
    const updatedAddressList =
      this.state.agGridAddressUtils.getUpdatedRowData();
    updatedAddressList.forEach((rowData) => {
      const lovCountry =
        rowData["countryId"] === 0
          ? null
          : DataService.getFirstOrDefault(
              this.state.countryList.filter(
                (x) => x.id === rowData["countryId"]
              )
            );
      const objCountry = lovCountry
        ? {
            CountryId: lovCountry.id,
            CountryName: lovCountry.value,
            CountryCode: "",
          }
        : { CountryId: null, CountryName: "", CountryCode: "" };

      const profileAddressModel = ProfileTabService.getAddressObjectForPost(
        rowData["isprimary"],
        rowData["isactive"],
        rowData["addressid"],
        rowData["addresS1"],
        rowData["addresS2"],
        rowData["addresS3"],
        rowData["addresS4"],
        rowData["city"],
        rowData["province"],
        rowData["postalcode"],
        rowData["porziogstaddressid"],
        rowData["companyaddressid"],
        objCountry
      );
      dataToPost.addresslist.push(profileAddressModel);
    });

    // contacts-grid
    dataToPost.contactlist = []; // Don't use this
    dataToPost.contacts = [];
    if (
      this.state.allGroupFieldsMap.get(
        ProfileTabService.contactMethodsFieldGroupKey
      ).fieldConfigs.length > 0
    ) {
      var updatedContactList =
        this.state.agGridContactUtils.getUpdatedRowData();
      updatedContactList.forEach((rowData) => {
        const lovCountry =
          rowData["countryId"] === 0
            ? null
            : DataService.getFirstOrDefault(
                this.state.countryList.filter(
                  (x) => x.id === rowData["countryId"]
                )
              );
        const objCountry = lovCountry
          ? { countryId: lovCountry.id, countryName: lovCountry.value }
          : { countryId: null, countryName: "" };

        const lovContactType = DataService.getFirstOrDefault(
          this.state.contactTypeList.filter(
            (x) => x.lovId === rowData["contacttypeid"]
          )
        );
        const objContactType = lovContactType
          ? {
              lovId: lovContactType.lovId,
              lovKey: lovContactType.lovKey,
              ordinal: lovContactType.ordinal,
            }
          : { lovId: "", lovKey: "", ordinal: "" };

        const contactObj = ProfileTabService.getContactsObject(
          rowData["ispreferred"],
          rowData["isactive"],
          rowData["companycontactid"],
          rowData["contactid"],
          rowData["contactinformation"],
          rowData["porziogstcontactid"],
          objContactType,
          objCountry
        );

        delete contactObj.conatacttypelist; // remove conatacttypelist key
        delete contactObj.countryId; // remove countryId key
        delete contactObj.countrylist; // remove countrylist key
        dataToPost.contacts.push(contactObj);
      });
    }
    dataToPost.contacts = DataService.arrayToSingleQuotedJsonString(
      dataToPost.contacts,
      ""
    );

    // identifiers-grid
    dataToPost.identifierlist = [];
    if (
      this.state.allGroupFieldsMap.get(
        ProfileTabService.identifierFieldGroupKey
      ).fieldConfigs.length > 0
    ) {
      const updatedIdentifierlist =
        this.state.agGridIdentifierUtils.getUpdatedRowData();
      updatedIdentifierlist.forEach((rowData) => {
        const lovPorzioGSTIdentifierType = DataService.getFirstOrDefault(
          this.state.porzioGstIdentifierTypeList.filter(
            (x) => x.lovId === rowData["porzioGSTIdentifierTypeId"]
          )
        );
        const objPorzioGSTIdentifierType = lovPorzioGSTIdentifierType
          ? {
              lovId: lovPorzioGSTIdentifierType.lovId,
              lovKey: lovPorzioGSTIdentifierType.lovKey,
            }
          : { lovId: 0, lovKey: "" };

        const lovCountryInfo = DataService.getFirstOrDefault(
          this.state.countryList.filter((x) => x.id === rowData["countryId"])
        );
        const objCountryInfo = lovCountryInfo
          ? { countryId: lovCountryInfo.id, countryName: lovCountryInfo.value }
          : { countryId: "", countryName: "" };

        const objIdentifier = ProfileTabService.getIdentifierObject(
          rowData["porzioGSTIdentifierTypeId"],
          rowData["companyIdentifierID"],
          rowData["countryId"],
          objCountryInfo,
          rowData["identifierDescription"],
          rowData["identifierValue"],
          objPorzioGSTIdentifierType,
          rowData["porzioGSTIdentifierID"],
          rowData["profileIdentifierId"]
        );
        dataToPost.identifierlist.push(objIdentifier);
      });
    }

    // consents-grid
    dataToPost.consentlist = [];
    if (
      this.state.allGroupFieldsMap.get(ProfileTabService.consentFieldGroupKey)
        .fieldConfigs.length > 0
    ) {
      const updatedConsentlist =
        this.state.agGridConsentUtils.getUpdatedRowData();
      updatedConsentlist.forEach((rowData) => {
        const objIdentifier = ProfileTabService.getConsentObject(
          rowData["profileConsentId"],
          rowData["profileConsent"],
          rowData["profileConsentName"],
          rowData["profileConsentStartDate"],
          rowData["profileConsentEndDate"],
          rowData["profileConsentExecuteDate"]
        );
        objIdentifier["profileConsentStartDate_Dt"] =
          objIdentifier["profileConsentStartDate"];
        objIdentifier["profileConsentEndDate_Dt"] =
          objIdentifier["profileConsentEndDate"];
        objIdentifier["profileConsentExecuteDate_Dt"] =
          objIdentifier["profileConsentExecuteDate"];

        dataToPost.consentlist.push(objIdentifier);
      });
    }

    //--- ensure mandatory fields
    dataToPost.tenantid = this.context.user.tenantId;
    dataToPost.userId = this.context.user.userId;
    dataToPost.userType = this.context.user.userTypeId;
    dataToPost.uid = this.context.user.uid;
    dataToPost.profileid = this.state.isNew ? 0 : this.state.selProfileId;
    dataToPost.sourceid = this.state.selSourceSystemId;
    dataToPost.recordid = this.state.initialValuesOrNull
      ? this.state.initialValuesOrNull.recordid
      : null;
    dataToPost.fileid = this.state.initialValuesOrNull
      ? this.state.initialValuesOrNull.fileid
      : null;
    dataToPost.ismanuallyentered = this.state.initialValuesOrNull
      ? this.state.initialValuesOrNull.ismanuallyentered
      : 1;
    dataToPost.isrecipientmatch = this.state.initialValuesOrNull
      ? this.state.initialValuesOrNull.isrecipientmatch
      : null;
    //---

    // return
    return dataToPost;
  };
}
export default LayoutService.getHocComponenet(ProfileTabComponent);
