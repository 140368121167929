import React from "react";
import { combineLatest, of, from } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Formik } from "formik";
import * as Yup from "yup";
import { ResultStatus, CrudAction, API_ENDPOINT } from "../../../../../shared/types/enums";
import { TextField, Grid } from "@material-ui/core";
import { AuthContext } from "../../../../../shared/store/authProvider";
import PageLoadingComponent from "../../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../../shared/components/page/pageErrorComponent";
import { SubscriptionArray } from "../../../../../shared/services/dataService";
import LayoutService from "../../../../../shared/services/layoutService";
import LookupService from "../../../../../shared/services/lookupService";
import ApiService from "../../../../../shared/services/apiService";
import SectionComponent from "../../../../../shared/components/sectionComponent";
import { DataService } from "../../../../../shared/services/dataService";
import PersonalSettingsService from "./personalSettingsStore";
import ToastService from "../../../../../shared/services/toastService";
import RolePermissionService from "../../../../../shared/role-permissions/rolePermissionService";
import OktaAuthService from "../../../../../public/authentication/oktaAuthService";

class PersonalSettingsModalComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();


  lastValidatedLoginResult = this.props.inputAction === CrudAction.UPDATE ? true : false;
  lastValidatedEmailResult = this.props.inputAction === CrudAction.UPDATE ? true : false;

  state = {
    fetchResult: ResultStatus.NOT_LOADED,
    isNew: this.props.inputAction !== CrudAction.UPDATE,
    isReadOnly: this.props.inputAction === CrudAction.UPDATE ? true : false,
    isEditing: this.props.inputAction === CrudAction.UPDATE ? true : false,

    validatedLoginName: "",
    validatedPrimaryEmail: "",
    // source-permission - lookups
    // affiliates
    isAffiliatesLoading: true,
    scopeAffiliationsList: [], // holds all
    selAffiliationsList: [], // holds chip selected
    // affiliate-vendors
    isVendorsLoading: true,
    scopeVendorsList: [], // filtered by affiliates
    selVendorsList: [], // holds chip selected
  };

  getSelectedAffiliations = (selAffiliations, allAffiliations) => {
    let selected = DataService.hasElements(selAffiliations) ? (allAffiliations || []).filter((sr) => selAffiliations.includes(sr.id)) : [];
    return selected;
  };

  getSelectedCountries = (selCountries, allCountries) => {
    let selected = DataService.hasElements(selCountries) ? (allCountries || []).filter((sr) => selCountries.includes(sr.id)) : [];
    return selected;
  };

  getSelectedVendors = (selVendors, allVendors) => {
    let selected = DataService.hasElements(selVendors) ? (allVendors || []).filter((sr) => selVendors.includes(sr.id)) : [];
    return selected;
  };
  // getSelectedRoles = (selRoles, allRoles) => {
  //   let selected = DataService.hasElements(selRoles) ? (allRoles || []).filter((sr) => selRoles.includes(sr.id)) : [];
  //   return selected;
  // };
  getSelectedTransactionType = (selTran, allTran) => {
    let selected = DataService.hasElements(selTran) ? (allTran || []).filter((sr) => selTran.includes(sr.id)) : [];
    return selected;
  };

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.setState({ fetchResult: ResultStatus.LOADING });

    this.oSubscriptions.add(
      combineLatest([
        !DataService.isValidNumber(this.props.userId) ? of(null) : PersonalSettingsService.getFullUserAsOBS(this.context.user.tenantId, this.props.userId),
        LookupService.getAffiliationsAsOBS(this.context),
        LookupService.getFormattedCountriesAsOBS(this.context, null),
        LookupService.getLanguagesAsOBS(this.context),
        LookupService.getTimezonesAsOBS(this.context),
        LookupService.getFormattedCurrenciesAsOBS(this.context, null),
        LookupService.getRolesAsOBS(this.context),
        LookupService.getTransactionTypesAsOBS(this.context),
      ]).subscribe(
        ([
          _fullUserDataOrNull,
          _scopeAffiliationsList,
          _countryList,
          _languageList,
          _timeZoneList,
          _currencyList,
          _roles,
          _transactionTypeList,
        ]) => {

          _roles = (_roles || []).filter((x) => x.isActive === true);
          // remove <all> from SCOPE affiliationList & transform its elemts into  {id: 1, text: "affialite 1"}
          _scopeAffiliationsList = DataService.getKeyValueCollection(_scopeAffiliationsList, "companyAffiliateId", "affiliateName", false); // no All

          var _selAffiliationsList = this.getSelectedAffiliations(_fullUserDataOrNull !== null ? _fullUserDataOrNull.selectedAffiliatedCompanyList : [], _scopeAffiliationsList);
          // remove all from selection
          _selAffiliationsList = _selAffiliationsList || [];
          _selAffiliationsList = DataService.removeElementAll(_selAffiliationsList, "id");

          var _selCountryList = this.getSelectedCountries(_fullUserDataOrNull !== null ? _fullUserDataOrNull.selectedCountryList : [], _countryList);
          _selCountryList = DataService.getKeyValueCollection(_selCountryList, "id", "value", false);

          _transactionTypeList = DataService.getKeyValueCollection(_transactionTypeList, "lovId", "lovKey", false);
          var _selTransactionTypeList = this.getSelectedTransactionType(_fullUserDataOrNull !== null ? _fullUserDataOrNull.selectedTransactionTypeList : [], _transactionTypeList);
          _selTransactionTypeList = DataService.getKeyValueCollection(_selTransactionTypeList, "id", "text", false);

          _countryList = DataService.getKeyValueCollection(_countryList, "id", "value", false);
          _languageList = DataService.getKeyValueCollection(_languageList, "id", "value", false);
          _timeZoneList = DataService.getKeyValueCollection(_timeZoneList, "id", "value", false);
          _currencyList = DataService.getKeyValueCollection(_currencyList, "id", "value", false);
          _roles = DataService.getKeyValueCollection(_roles, "roleId", "roleName", false);

          this.oSubscriptions.add(
            LookupService.getVendorsByAffiliationsAsOBS(this.context.user.tenantId, _selAffiliationsList)
              .subscribe((_scopeVendorsList) => {
                // remove <all> from SCOPE vendorList
                _scopeVendorsList = DataService.removeElementAll(_scopeVendorsList, "id"); // remove <all> before passing

                var _selVendorsList = this.getSelectedVendors(_fullUserDataOrNull !== null ? _fullUserDataOrNull.selectedVendorList : [], _scopeVendorsList);
                _selVendorsList = DataService.removeElementAll(_selVendorsList, "id");

                // var _selRoles = this.getSelectedRoles(_fullUserDataOrNull !== null ? _fullUserDataOrNull.selectedRolesList : [], _roles);
                // _selRoles = DataService.removeElementAll(_selRoles, "id");

                this.setState(
                  {
                    // source-permissions
                    scopeAffiliationsList: _scopeAffiliationsList.length > 0 ? DataService.addElementAll(_scopeAffiliationsList, "id") : [], // adds <all> element
                    scopeVendorsList: _scopeVendorsList.length > 0 ? DataService.addElementAll(_scopeVendorsList, "id") : [], // adds <all> element
                    countryList: _countryList.length > 0 ? DataService.addElementAll(_countryList, "id") : [], // adds <all> element

                    roles: _roles, //_roles.length > 0 ? DataService.addElementAll(_roles, "id") : [],
                    selRoleId: _fullUserDataOrNull?.roleId,//_selRoles.length > 0 && _roles.length === _selRoles.length ? [LookupService.allElement] : _selRoles,

                    // if both the scope and the selected length are equal then select only All
                    // items that are already selected if any
                    selAffiliationsList: _selAffiliationsList.length > 0 && _scopeAffiliationsList.length === _selAffiliationsList.length ? [LookupService.allElement] : _selAffiliationsList,
                    selVendorsList: _selVendorsList.length > 0 && _scopeVendorsList.length === _selVendorsList.length ? [LookupService.allElement] : _selVendorsList,

                    isAffiliatesLoading: false,
                    isVendorsLoading: false,
                    isUsersLoading: false,

                    fullUserData: _fullUserDataOrNull,
                    validatedLoginName: _fullUserDataOrNull?.primaryEmail,//_fullUserDataOrNull?.loginName,
                    validatedPrimaryEmail: _fullUserDataOrNull?.primaryEmail,

                    languageList: _languageList,
                    timeZoneList: _timeZoneList,
                    currencyList: _currencyList,
                    //selCountryList: _selCountryList,
                    selCountryList: _selCountryList.length > 0 && _countryList.length === _selCountryList.length ? [LookupService.allElement] : _selCountryList,

                    selTransactionTypeList: _selTransactionTypeList.length > 0 && _transactionTypeList.length === _selTransactionTypeList.length ? [LookupService.allElement] : _selTransactionTypeList,
                    transactionTypeList: DataService.addElementAll(_transactionTypeList, "id"),
                  },
                  // change the state after all the above are assigned
                  () => {
                    this.setState({ fetchResult: ResultStatus.LOADED });
                  }
                );
              })
          );
        }
      )
    );
  }

  getInitialValues() {
    return {
      firstName: this.state.isNew ? "" : this.state.fullUserData.firstName,
      middleInitial: this.state.isNew ? "" : this.state.fullUserData.middleInitial,
      lastName: this.state.isNew ? "" : this.state.fullUserData.lastName,
      title: this.state.isNew ? "" : this.state.fullUserData.title,
      activeID: this.state.isNew ? true : this.state.fullUserData.isActive,
      primaryEmail: this.state.isNew ? "" : this.state.fullUserData.primaryEmail,
      loginName: this.state.isNew ? "" : this.state.fullUserData.primaryEmail, //this.state.isNew ? "" : this.state.fullUserData.loginName,
      department: this.state.isNew ? "" : this.state.fullUserData.department,
      language: this.state.isNew ? "" : this.state.fullUserData.defaultLanguageId,
      timeZone: this.state.isNew ? "" : this.state.fullUserData.timeZoneId,
      currency: this.state.isNew ? "" : this.state.fullUserData.currencyId,

      // role: this.state.isNew ? "" : this.state.fullUserData.selectedRolesList,
      roleId: this.state.isNew ? "" : this.state.selRoleId,


      selAffiliationsList: this.state.selAffiliationsList,
      selVendorsList: this.state.selVendorsList,

      selCountryList: this.state.selCountryList,
      selTransactionTypeList: this.state.selTransactionTypeList,
    };
  }

  //validatedLoginName = "";
  //alidatedPrimaryEmail = "";
  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    this.validationSchema = Yup.object().shape({

      firstName: Yup.string().min(3, "Must be atleast 3 characters long").max(256, "Must be 50 characters or less").required("Required"),
      middleInitial: Yup.string(),
      lastName: Yup.string().min(3, "Must be atleast 3 characters long").max(256, "Must be 50 characters or less").required("Required"),
      title: Yup.string(),
      primaryEmail: Yup.string().email("Primary Email is not valid").required("Required").min(3, "Must be at least 3 characters long").max(50, "Must be 50 characters or less")
        .test("checkDuplicateEmail", "Email ID already exists", async (_formikValue) => {
          if (this.props.inputAction === CrudAction.UPDATE && _formikValue === this.state.fullUserData.primaryEmail) {
            return true;
          } else {
            if (_formikValue === this.state.validatedPrimaryEmail) { return this.lastValidatedEmailResult; }
            else {
              try {
                const result = await fetch(`${process.env.REACT_APP_TENANT_HTTP}/User/CheckUserExists/${_formikValue}`, {
                  method: "GET",
                  headers: {
                    Authorization: `Bearer ` + OktaAuthService.getAccessToken(),
                  },
                });
                const isExists = await result.json();
                console.log("is primary email exists: ", !isExists);
                this.lastValidatedEmailResult = isExists;
                this.setState({ validatedPrimaryEmail: _formikValue });
                //this.validatedPrimaryEmail = _formikValue;
                return this.lastValidatedEmailResult;
              } catch {
                this.setState({ fetchResult: ResultStatus.ERROR });
                return false;
              }
            }
          }
        }),
      department: Yup.string(),
      roleId: Yup.number().required(),
      selAffiliationsList: DataService.hasElements(this.state.scopeAffiliationsList) ? Yup.array().required("Required") : Yup.array(),
      selCountryList: DataService.hasElements(this.state.countryList) ? Yup.array().required("Required") : Yup.array(),
      selVendorsList: DataService.hasElements(this.state.selVendorsList) ? Yup.array().required("Required") : Yup.array(),
      selTransactionTypeList: DataService.hasElements(this.state.transactionTypeList) ? Yup.array().required("Required") : Yup.array(),
      language: Yup.number().required("Required").typeError("Required"),
      timeZone: Yup.number().required("Required").typeError("Required"),
      currency: Yup.number().required("Required").typeError("Required"),
    });
    return this.validationSchema;
  }

  handleSubmit = async (_formikProps) => {
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
        .then((x) => {

          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING });

          const selRole = DataService.getFirstOrDefault(this.state.roles.filter(x => x.id === _formikProps.values.roleId), null);

          // 2) extract the formik values into an object
          var mappedObj = {
            // userID is set conditionally below
            loginName: _formikProps.values.primaryEmail, //this.state.fullUserData !== null ? this.state.fullUserData.loginName : _formikProps.values.primaryEmail,
            password: this.state.fullUserData !== null ? this.state.fullUserData.password : "Porzio@1234",
            tempPassword: "",
            firstName: _formikProps.values.firstName,
            lastName: _formikProps.values.lastName,
            isPorzioUser: this.state.fullUserData !== null ? this.state.fullUserData.isPorzioUser : false,
            tenantId: this.context.user.tenantId,
            userType: this.context.user.userTypeId,
            lastLoginDate: "2020-09-22T17:31:43.114Z",
            defaultLanguageId: _formikProps.values.language,
            middleInitial: _formikProps.values.middleInitial ?? '',
            title: _formikProps.values.title,
            primaryEmail: _formikProps.values.primaryEmail,
            department: _formikProps.values.department,
            isActive: _formikProps.values.activeID,
            timeZoneId: _formikProps.values.timeZone,
            currencyId: _formikProps.values.currency,

            roleId: selRole ? selRole.id : "",
            roleName: selRole ? selRole.text : "",
            selectedRolesList: [], // DataService.getScopeKeys(_formikProps.values.selRolesList, this.state.roles, "id"),
            selectedRolesListNames: [], // DataService.getScopeKeys(_formikProps.values.selRolesList, this.state.roles, "text"),
            rolesIntList: _formikProps.values.roleId ? [_formikProps.values.roleId] : [], //DataService.getScopeKeys(_formikProps.values.selRolesList, this.state.roles, "id"),
            roles: "Master Data",

            selectedAffiliatedCompanyList: DataService.getScopeKeys(this.state.selAffiliationsList, this.state.scopeAffiliationsList, "id"),
            selectedAffiliatedCompanyListNames: DataService.getScopeKeys(this.state.selAffiliationsList, this.state.scopeAffiliationsList, "text"),
            selectedCountryList: DataService.getScopeKeys(_formikProps.values.selCountryList, this.state.countryList, "id"),
            selectedCountryListNames: DataService.getScopeKeys(_formikProps.values.selCountryList, this.state.countryList, "text"),
            selectedVendorList: DataService.getScopeKeys(this.state.selVendorsList, this.state.scopeVendorsList, "id"),
            selectedVendorListNames: DataService.getScopeKeys(this.state.selVendorsList, this.state.scopeVendorsList, "text"),
            selectedTransactionTypeList: DataService.getScopeKeys(_formikProps.values.selTransactionTypeList, this.state.transactionTypeList, "id"),
            selectedTransactionTypes: DataService.getScopeKeys(_formikProps.values.selTransactionTypeList, this.state.transactionTypeList, "text"),

            addressList: this.state.fullUserData !== null ? this.state.fullUserData.addressList : [],
            contactList: this.state.fullUserData !== null ? this.state.fullUserData.contactList : [],
            createdBy: 0,
            updatedBy: 0,
            searchUserText: "",
            lastLogin: "2020-09-22T17:31:43.114Z",
            userName: _formikProps.values.primaryEmail, //this.state.fullUserData !== null ? this.state.fullUserData.loginName : _formikProps.values.primaryEmail,
            isCopy: false,
            addresses: "",
            contacts: "",
            tenantConnectionString: "",
            isLocked: false,
            tenantName: "",
            status: "",
            parentTenant: "",
            email: this.state.fullUserData !== null ? this.state.fullUserData.primaryEmail : _formikProps.values.primaryEmail, rolewithinTenant: "",
            loggedInUser: "",
            userPermissions: {
              isUserListView: true,
              isUserListExportView: true,
              isPersonalInformationCreate: true,
              isPersonalInformationView: true,
              isPersonalInformationEdit: true,
              isPersonalInformationDelete: true,
              isPersonalInformationViewDelete: true,
              isPrimaryEmailView: true,
              isRolesView: true,
              isRolesEdit: true,
              isActiveInactiveView: true,
              isActiveInactiveEdit: true,
              isOrganizationalMembershipView: true,
              isOrganizationalMembershipEdit: true,
              isAuthenticationView: true,
              isAuthenticationEdit: true,
              isCommunicationsView: true,
              isCommunicationsEdit: true,
              isUserAuditView: true,
            },
          };

          // 3) determine the action and assign the appropriate props
          var actionVerb = "";
          if (this.state.isEditing) {
            // UPDATE
            actionVerb = "POST";
            mappedObj.userID = this.props.userId;
            mappedObj.updatedBy = this.context.user.userId;
          } else {
            // CREATE
            actionVerb = "POST";
            mappedObj.userID = 0;
            mappedObj.createdBy = this.context.user.userId;
          }

          // 4) save to Api and subscribe for the result
          ApiService.setOBS(actionVerb, API_ENDPOINT.TENANT, `/User/SaveUser`, JSON.stringify(mappedObj))
            .subscribe(
              (successResult) => {
                this.setState({ fetchResult: ResultStatus.SUCCESS }, () => {
                  console.log(successResult);
                  if (successResult) {
                    ToastService.showSuccess("Sucessfully Saved.");
                    this.props.refreshParentGrid(true);
                    this.props.onClose(false);
                  } else {
                    ToastService.showError("Error while saving.");
                    this.setState({ fetchResult: ResultStatus.ERROR });
                  }
                });
              },
              (errorResult) => {
                ToastService.showError("Error while saving.");
                this.setState({ fetchResult: ResultStatus.ERROR });
              }
            );
        })
        .catch((errorObj) => {
          console.log(errorObj);
          ToastService.showError("Error while saving.");
          (errorObj.inner || []).forEach(err => { _formikProps.setFieldError(err.path, err.message); });
        });
    }
  }
  getLanguageList(list) {
    let english = list.find(x => x.id === 1);
    let newList = list.filter(x => x.id !== 1);
    newList.unshift(english);
    return newList;
  }


  render() {
    const { classes } = this.props;
    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (<PageLoadingComponent small classes={classes} label="Loading User Personal Settings" />);
      case ResultStatus.SAVING:
        return (<PageLoadingComponent small classes={classes} label="Saving Personal Settings" />);
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
        return (
          <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }}>
            {(fProps) => (
              <form>
                <Grid container spacing={1}>

                  <Grid item xs={12}>
                    <SectionComponent classes={classes} enableEditJsx={RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION.cannotEdit ? null :
                      LayoutService.getReadOnlyActionsSolo(this, this.state.isNew, () => { this.handleSubmit(fProps) })} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "firstName", "First Name")} style={{ minWidth: "80%" }} /></Grid>
                  <Grid item xs={12} sm={6} md={4}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "middleInitial", "Middle Initial", false)} style={{ minWidth: "80%" }} /></Grid>
                  <Grid item xs={12} sm={6} md={4}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "lastName", "Last Name")} style={{ minWidth: "80%" }} /></Grid>
                  <Grid item xs={12} sm={6} md={4}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "title", "Title", false)} style={{ minWidth: "80%" }} /></Grid>

                  {!this.state.isNew && RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION_PRIMARY_EMAIL.cannotView ? null :
                    <Grid item xs={12} sm={6} md={4}><TextField {...LayoutService.getInputProps(!this.state.isNew, classes, fProps, "primaryEmail", "User Name (Email)")} style={{ minWidth: "80%" }} /></Grid>
                  }

                  {/* <Grid item xs={12} sm={6} md={4}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "loginName", "User Name")} style={{ minWidth: "80%" }} /></Grid> */}
                  <Grid item xs={12} sm={6} md={4}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "department", "Department", false)} style={{ minWidth: "80%" }} /></Grid>

                  {/* Tenant admin can modify the role regardless of the permissions */}
                  {!this.state.isNew && RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION_ROLES.cannotView ? null :
                    <Grid item xs={12} sm={6}>{LayoutService.getSelectControl(this.state.isReadOnly || (!this.state.isNew && RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION_ROLES.cannotEdit),
                      classes, fProps, "roleId", "Role *", this.state.roles, "id", "text", "80%")}</Grid>
                  }

                  {!this.state.isNew && RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION_ACTIVE_INACTIVE.cannotView ? null :
                    <Grid item xs={12} sm={6} md={4} style={{ marginTop: "22px" }}>{LayoutService.getSwitch(this.state.isReadOnly || (!this.state.isNew && RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION_ACTIVE_INACTIVE.cannotEdit),
                      classes, fProps, "activeID", "Active")}</Grid>
                  }

                  {!this.state.isNew && RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION_ORG_MEMBERSHIP.cannotView ? null :
                    <>
                      <Grid item xs={12}><SectionComponent classes={classes} label={"Organizational Membership"} /></Grid>

                      <Grid item xs={12} sm={6}>{this.state.isAffiliatesLoading ? null
                        : LayoutService.getChipSelect(this.state.isReadOnly || (!this.state.isNew && RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION_ORG_MEMBERSHIP.cannotEdit),
                          classes, fProps, "selAffiliationsList", "Affiliated Companies *", this.state.scopeAffiliationsList, "id", "text", this.onAffiliatesChange, "80%")}</Grid>
                      <Grid item xs={12} sm={6}>
                        {LayoutService.getChipSelect(this.state.isReadOnly || (!this.state.isNew && RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION_ORG_MEMBERSHIP.cannotEdit),
                          classes, fProps, "selCountryList", "Country Name *", this.state.countryList, "id", "text",
                          (_formikProps, _newValues) => { this._clearOthersIfAllIsSelected(_newValues, _formikProps, "selCountryList"); }, "80%")}
                      </Grid>
                      <Grid item xs={12} sm={6}> {this.state.isVendorsLoading || DataService.hasNoElements(this.state.selAffiliationsList) ? null
                        : LayoutService.getChipSelect(this.state.isReadOnly || (!this.state.isNew && RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION_ORG_MEMBERSHIP.cannotEdit),
                          classes, fProps, "selVendorsList", "Vendors", this.state.scopeVendorsList, "id", "text", this.onVendorsChange, "80%")}</Grid>
                      <Grid item xs={12} sm={6}>
                        {LayoutService.getChipSelect(this.state.isReadOnly || (!this.state.isNew && RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION_ORG_MEMBERSHIP.cannotEdit),
                          classes, fProps, "selTransactionTypeList", "Transaction Type *", this.state.transactionTypeList, "id", "text",
                          (_formikProps, _newValues) => { this._clearOthersIfAllIsSelected(_newValues, _formikProps, "selTransactionTypeList"); }, "80%")}
                      </Grid>
                    </>
                  }


                  <Grid item xs={12}><SectionComponent classes={classes} label={"Defaults"} /></Grid>
                  <Grid item xs={12} sm={6}>{LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "language", "Language *", this.getLanguageList(this.state.languageList), "id", "text", "80%")}</Grid>
                  <Grid item xs={12} sm={6}>{LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "timeZone", "Time Zone *", this.state.timeZoneList, "id", "text", "80%")}</Grid>
                  <Grid item xs={12} sm={6}>{LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "currency", "Currency *", this.state.currencyList, "id", "text", "80%")}</Grid>

                </Grid>
              </form>
            )
            }
          </Formik >
        );

      case ResultStatus.ERROR:
      default:
        return (
          <PageErrorComponent small label="Error Loading User Personal Settings" classes={classes} onRetry={() => { }} />
        );
    }
  } /*  */

  onAffiliatesChange = async (_formikProps, _newValues) => {
    // 1) clear the selected userList & its datasource
    await this._clearLookupStuff(_formikProps, "isUsersLoading", "selUsersList", "scopeUsersList");
    // 2) clear the selected vendorList & its datasource
    await this._clearLookupStuff(_formikProps, "isVendorsLoading", "selVendorsList", "scopeVendorsList");
    // 3) set the newely selectedAffiliations
    this.setState({ selAffiliationsList: _newValues }); // this is not formik equivalent, but updating just for reference
    // 4) filterFetch the vendorsList and set it as the datasource
    if (DataService.hasElements(_newValues)) {
      // clear others if <All> is selected
      await this._clearOthersIfAllIsSelected(_newValues, _formikProps, "selAffiliationsList");
      // fetch and assign
      LookupService.getVendorsByAffiliationsAsOBS(this.context.user.tenantId, _newValues)
        .subscribe(
          (successResult) => {
            // load datasource for vendors-ChipSelect
            this.setState({
              scopeVendorsList: successResult.length > 0 ? DataService.addElementAll(successResult, "id") : [],
              isVendorsLoading: false,
            });
          },
          (errorResult) => {
            ToastService.showError("Error loading Affiliate-Vendors");
            console.log("Error loading Affiliate-Vendors", errorResult);
          }
        );
    }
  };

  onVendorsChange = async (_formikProps, _newValues) => {
    // 1) set the newely selUsersList
    this.setState({ selVendorsList: _newValues }); // this is not formik equivalent, but updating just for reference
    // 2) clear others if <All> is selected
    if (DataService.hasElements(_newValues)) {
      await this._clearOthersIfAllIsSelected(_newValues, _formikProps, "selVendorsList");
    }
  };

  // Utils
  _clearOthersIfAllIsSelected = async (_newValues, _formikProps, _propName) => {
    // check for <All> Item
    const itemAll = DataService.getFirstOrDefault(
      _newValues.filter((x) => x.id === LookupService.allElement.id)
    );
    // if <all> is selected and is not the only selected item, then clear others
    if (itemAll && _newValues.length !== 1) {
      // then clear the others except All
      await _formikProps.setFieldValue(_propName, [itemAll], true);
      this.setState({ [_propName]: [itemAll] });
    }
  };

  _clearLookupStuff = async (_formikProps, _loadingKey, _selectionKey, _scopeKey) => {
    this.setState({ [_loadingKey]: true }); // set the vendors loading as true
    await _formikProps.setFieldValue(_selectionKey, [], true);
    await _formikProps.setFieldTouched(_selectionKey, true, false);
    this.setState({ [_selectionKey]: [], [_scopeKey]: [] }); // clear the user datasource
  };

  //---
}
/** HOC */
export default LayoutService.getHocComponenet(PersonalSettingsModalComponent);