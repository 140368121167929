import React from "react";
import { ReplaySubject, combineLatest, of } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Divider, Typography, Grid, Dialog, LinearProgress, DialogTitle, DialogContent, AppBar, Toolbar, TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import AgGridEditButtonCellRendererComponent from "../../../../shared/components/elements/agGridEditButtonCellRendererComponent";

import { AuthContext } from "../../../../shared/store/authProvider";
import { API_ENDPOINT, CrudAction, ENTITY_FIELD_TYPE, ENTITY_TYPE, ResultStatus } from "../../../../shared/types/enums";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import LookupService from "../../../../shared/services/lookupService";
import LayoutService from "../../../../shared/services/layoutService";
import DynamicControlService from "../../../../shared/services/dynamicControlService";

import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import MatThemeService from "../../../../shared/services/theme/matThemeService";
import TransactionTabService from "./transactionTabService";
import ApiService from "../../../../shared/services/apiService";
import { MatIconService } from "../../../../shared/services/theme/matIconService";

import RecipientSearchDialogComponent from "./recipient-information-dialog/recipinetSearchDialogComponent";
import ToastService from "../../../../shared/services/toastService";
import PossibleMatchesDialogComponent from "../../../../shared/components/possible-matches/possibleMatchesDialogComponent";
import ProfileDetailDialogComponent from "../../../profile-center/profile-detail/profileDetailDialogComponent";
import SalesRepDetailsComponent from "../../../tenant-profile-setup/sales-rep/sales-rep-details/salesRepDetailsComponent";
import StatusOverrideComponenet from "../../../../shared/components/profiles-transactions/StatusOverrideComponent";
import ProfileTabService from "../../../profile-center/profile-detail/tabs/profileTabService";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";

class TransactionTabComponent extends React.Component {

    static contextType = AuthContext;
    oSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);

        // init state
        this.state = {
            isNew: this.props.inputAction === CrudAction.CREATE,
            isClone: DataService.hasValue(this.props.transactionToClone),
            cleanedTransactionToClone: TransactionTabService.cleanupClone(this.props.transactionToClone),
            selTransactionId: !props.modalAgNode ? "" : props.modalAgNode.transactionid,
            // 1) source-name
            fetchSourceSystemsResult: ResultStatus.NOT_LOADED,
            sourceSystemList: [],
            selSourceSystemId: (this.props.transactionToClone && this.props.transactionToClone.sourceid > 0) ?
                this.props.transactionToClone.sourceid : (!props.modalAgNode ? "" : props.modalAgNode.sourceid),

            templateFetchResult: ResultStatus.NOT_LOADED,
            allGroupFieldsMap: new Map(),
            dynamicInitialValies: {},
            initialValuesOrNull: {},
            dynamicValidationSchema: Yup.object().shape({}),
            showSearchRecipientDialog: false,
            showPossibleMatchesDialog: false,
            showConfirmCreateNewProfile: false,
            showCreateNewProfileDialog: false,

            showPIDProfileSearchDialog: false, // opens the profile search-dialog for pid
            targetPidFieldConfig: {}, // stores the pid field name which triggered the profile search
            piIdMap: new Map(), // map used to store the values
            prIdpiIdMap: new Map(), // map used to store the values

            showProfileDetailDialog: false,

            agGridRecipientInformationUtils: new AgGridUtil("lastname", {
                inlineEditButtonCellRendererComponent: AgGridEditButtonCellRendererComponent,
            }),

            recipientProfileInfo: {},
            recipientIdentifierInfo: {},
            recipientAddressInfo: {},
        };
    }

    componentWillUnmount() { }
    componentDidMount() {
        LookupService.fetchCommonLookups(this.context);
        this.fetchSourceSystems();
        //console.log("Clone", this.props.transactionToClone);
    }

    //#region SourceSystem DropDown

    // api
    fetchSourceSystems = () => {
        this.oSubscriptions.cancelAll();

        // set the loading state
        this.setState({ fetchSourceSystemsResult: ResultStatus.LOADING });
        // fetch-source-list
        this.oSubscriptions.add(
            LookupService.getSourceSystemsByEntityAsOBS(this.context.user.tenantId, ENTITY_TYPE.TRANSACTION).subscribe(
                (_sourceSystemList) => {
                    _sourceSystemList = DataService.getKeyValueCollection(_sourceSystemList, "sourceId", "sourceName", false);
                    // set it in the state
                    this.setState({ sourceSystemList: _sourceSystemList },
                        () => {
                            this.onSourceSystemChange(this.state.selSourceSystemId, true);
                            this.setState({ fetchSourceSystemsResult: ResultStatus.LOADED });
                        }
                    );
                },
                (err) => {
                    ToastService.showError("An Error occured while fetching the SourceSystems");
                    this.setState({ sourceSystemList: [] },
                        () => { this.setState({ fetchSourceSystemsResult: ResultStatus.ERROR }); }
                    );
                }
            )
        );
    }

    getUnmatchedProfileObj = () => {
        const countryObj = LookupService._COUNTRIES.find(c => c.id === this.fPropsDynamic.values["country"]);
        const profileType = TransactionTabService.recipinetProfileTypeLovOptions.find(x => x.lovId === this.fPropsDynamic.values["profiletypeid"]);
        const recipientIdentifierType = TransactionTabService.recipinetIdentifierTypeLovOptions.find(x => x.lovId === this.fPropsDynamic.values["recipientidentifiertypeid"]);

        // object for PossibleMatchesDialogService->getAsMatchCenterRow()
        return {
            "companyProfileId": this.fPropsDynamic.values["companyprofileid"],
            "porziogstprofileid": this.fPropsDynamic.values["porziogstprofileid"],
            "matchScore": "",
            "matchKey": "",
            "profileType": profileType ? profileType["localLovKey"] : "",
            "lastName": this.fPropsDynamic.values["lastname"],
            "firstName": this.fPropsDynamic.values["firstname"],
            "middleName": this.fPropsDynamic.values["middlename"],
            "usnpinumber": this.fPropsDynamic.values["usnpinumber"],
            "uslicensestate": this.fPropsDynamic.values["uslicensestate"],
            "usstatelicensenumber": this.fPropsDynamic.values["usstatelicensenumber"],
            "usTaxIdNumber": this.fPropsDynamic.values["ustaxidnum"],
            "organizationName": this.fPropsDynamic.values["organizationname"],
            "city": this.fPropsDynamic.values["city"],
            "province": this.fPropsDynamic.values["province"],
            "postalCode": this.fPropsDynamic.values["postalcode"],
            "countryName": countryObj ? countryObj.value : "",
            "profileStatusKey": "",
            "porzioGstIdentifierType": recipientIdentifierType ? recipientIdentifierType["localLovKey"] : "",
            "identifierValue": this.fPropsDynamic.values["recipientidentifiervalue"],
        }
    }

    //selectedProfile["prid"]
    generateRecipientInfo = (_selProfileId) => {
        if (DataService.isValidNumber(_selProfileId) && _selProfileId > 0) {
            this.setState({ isRecipientInfoInProgress: true });
            this.isRecipientMatched = true;
            this.oSubscriptions.add(
                combineLatest([
                    LookupService.getProfileTabDetailsWithErrorInfoAsOBS(this.context.user.tenantId, _selProfileId),
                    ApiService.getOBS(API_ENDPOINT.CORE, `/Profiles/FetchProfileIdentifier?tenantId=${this.context.user.tenantId}&prid=${_selProfileId}&pageNum=1&rowsPerPage=100`)
                ]).subscribe(
                    ([_profile, _identifier]) => {
                        const sortedIdentifiers = _identifier.length === 0 ? null : _identifier.sort((a, b) => b.profileIdentifierId - a.profileIdentifierId)[0];
                        const addressInfo = _profile.addresslist.length === 0 ? null : _profile.addresslist.length === 1 ? _profile.addresslist[0]
                            : _profile.addresslist.some(x => x.isprimary === true) ? _profile.addresslist.filter(add => add.isprimary === true)[0] :
                                _profile.addresslist.sort((a, b) => { return b.updateddate - a.updateddate })[0];
                        this.setState({
                            prid: _selProfileId,
                            recipientProfileInfo: _profile,
                            recipientIdentifierInfo: sortedIdentifiers,
                            recipientAddressInfo: addressInfo
                        }, () => {
                            this.setState({ porziogstprofileid: this.state.recipientProfileInfo.porziogstprofileid });
                            this.fPropsDynamic.setFieldValue("matchKey", "Manual Match", false);
                            this.fPropsDynamic.setFieldValue("porziogstprofileid", this.state.recipientProfileInfo.porziogstprofileid, false);
                            this.fPropsDynamic.setFieldValue("companyprofileid", this.state.recipientProfileInfo.companyprofileid, false);
                            this.fPropsDynamic.setFieldValue("profiletypeid", this.state.recipientProfileInfo.profiletypeid, false);
                            this.fPropsDynamic.setFieldValue("recipientcategoryid", this.state.recipientProfileInfo.profilecategoryid, false);
                            this.fPropsDynamic.setFieldValue("organizationname", DataService.getEmptyStringIfNull(this.state.recipientProfileInfo, "organizationname"), false);
                            this.fPropsDynamic.setFieldValue("suffix", DataService.getEmptyStringIfNull(this.state.recipientProfileInfo, "suffix"), false);
                            this.fPropsDynamic.setFieldValue("lastname", DataService.getEmptyStringIfNull(this.state.recipientProfileInfo, "lastname"), false);
                            this.fPropsDynamic.setFieldValue("middlename", DataService.getEmptyStringIfNull(this.state.recipientProfileInfo, "middlename"), false);
                            this.fPropsDynamic.setFieldValue("firstname", DataService.getEmptyStringIfNull(this.state.recipientProfileInfo, "firstname"), false);
                            this.fPropsDynamic.setFieldValue("prefix", DataService.getEmptyStringIfNull(this.state.recipientProfileInfo, "prefix"), false);
                            this.fPropsDynamic.setFieldValue("credentialsid", DataService.getEmptyStringIfNull(this.state.recipientProfileInfo, "credentialsid"), false);
                            this.fPropsDynamic.setFieldValue("specialityid", DataService.getEmptyStringIfNull(this.state.recipientProfileInfo, "specialtyid"), false);
                            this.fPropsDynamic.setFieldValue("address1", DataService.getEmptyStringIfNull(this.state.recipientAddressInfo, "addresS1"), false);
                            this.fPropsDynamic.setFieldValue("address2", DataService.getEmptyStringIfNull(this.state.recipientAddressInfo, "addresS2"), false);
                            this.fPropsDynamic.setFieldValue("address3", DataService.getEmptyStringIfNull(this.state.recipientAddressInfo, "addresS3"), false);
                            this.fPropsDynamic.setFieldValue("address4", DataService.getEmptyStringIfNull(this.state.recipientAddressInfo, "addresS4"), false);
                            this.fPropsDynamic.setFieldValue("city", DataService.getEmptyStringIfNull(this.state.recipientAddressInfo, "city"), false);
                            this.fPropsDynamic.setFieldValue("province", DataService.getEmptyStringIfNull(this.state.recipientAddressInfo, "province"), false);
                            this.fPropsDynamic.setFieldValue("country", DataService.getEmptyStringIfNull(this.state.recipientAddressInfo, "countryId"), false);
                            this.fPropsDynamic.setFieldValue("postalcode", DataService.getEmptyStringIfNull(this.state.recipientAddressInfo, "postalcode"), false);
                            this.fPropsDynamic.setFieldValue("usnpinumber", DataService.getEmptyStringIfNull(this.state.recipientProfileInfo, "usnpinumber"), false);
                            this.fPropsDynamic.setFieldValue("uslicensestate", DataService.getEmptyStringIfNull(this.state.recipientProfileInfo, "uslicensestate"), false);
                            this.fPropsDynamic.setFieldValue("usstatelicensenumber", DataService.getEmptyStringIfNull(this.state.recipientProfileInfo, "usstatelicensenumber"), false);
                            this.fPropsDynamic.setFieldValue("ustaxidnum", DataService.getEmptyStringIfNull(this.state.recipientProfileInfo, "ustaxidnumber"), false);
                            this.fPropsDynamic.setFieldValue("recipientidentifiercountryid", DataService.getEmptyStringIfNull(this.state.recipientIdentifierInfo, "countryId"), false);
                            this.fPropsDynamic.setFieldValue("recipientidentifiertypeid", DataService.getEmptyStringIfNull(this.state.recipientIdentifierInfo, "porzioGSTIdentifierTypeId"), false);
                            this.fPropsDynamic.setFieldValue("recipientidentifiervalue", DataService.getEmptyStringIfNull(this.state.recipientIdentifierInfo, "identifierValue"), false);
                            this.fPropsDynamic.setFieldValue("customermasterid", DataService.getEmptyStringIfNull(this.state.recipientProfileInfo, "customermasterid"), false);
                            ToastService.showInfo("Recipient details loaded.");
                            this.setState({ isRecipientInfoInProgress: false });
                        })
                    },
                    (errorObj) => {
                        ToastService.showError("An Error Occured while loading the profile details.");
                        this.setState({ isRecipientInfoInProgress: false });
                    }
                )
            )
            this.setState({
                profileId: _selProfileId,
                showSearchRecipientDialog: false,
                showPossibleMatchesDialog: false,
                showConfirmCreateNewProfile: false,
                showCreateNewProfileDialog: false,
            });
        }
    }

    // formik
    getSourceSystemInitialValues() {
        return {
            sourceSystemId: this.state.selSourceSystemId,
            transactionfilename: ""
        };
    }

    sourceSystemValidationSchema = Yup.object().shape({});
    getSourceSystemValidationSchema() {
        this.sourceSystemValidationSchema = Yup.object().shape({
            sourceSystemId: LayoutService.getNullableValidation(Yup.number().required("Required"))
        });
        return this.sourceSystemValidationSchema;
    }

    //#region PI Information related stuff
    _resetClickHandle = (_fieldConfig) => {
        setTimeout(() => {
            _fieldConfig.clickHandled = false; // reset so the linkClickCanWork
        }, 500);
    }
    onPidSearchClick = (_fieldConfig) => {
        if (this.props.isReadOnly) {
            ToastService.showWarning("Please switch to edit mode.");
            this._resetClickHandle(_fieldConfig); // reset so the linkClickCanWork
        } else {
            this.setState({
                targetPidFieldConfig: _fieldConfig, // set the caller config, will be reset on dialog close
                showPIDProfileSearchDialog: true,
            });
        }
    }
    onPidClearClick = (_fieldConfig) => {
        this._resetClickHandle(_fieldConfig); // reset so the linkClickCanWork
        if (this.props.isReadOnly) {
            ToastService.showWarning("Please switch to edit mode.");
        }
        else if (DataService.isStringNullOrEmpty(this.fPropsDynamic.values[_fieldConfig.mappedFieldNameLower])) {
            ToastService.showWarning("No Profile Selected.");
        }
        else {
            this.setPiIDandPridpiID(_fieldConfig, null, null);
            ToastService.showInfo("Cleared");
        }
    }


    setPiIDandPridpiID = (_fieldconfig, _piId, _pridpiid) => {
        //get the lastId (1|2|3|4|5) fieldName: piid2 // initalvaluesOrNull Key: pridpiid2
        const id = (_fieldconfig.mappedFieldNameLower + "").replace("piid", "");
        // this map is used for post (initially it holds the initial values, now it holds the updated value)
        this.state.piIdMap.set(`piid${id}`, _piId);
        this.state.prIdpiIdMap.set(`pridpiid${id}`, _pridpiid);
        // if exists then assign directly to the initialvalues (not using setstate to prevent unecessary rerenders)
        if (this.state.initialValuesOrNull !== null) {
            this.state.initialValuesOrNull[`piid${id}`] = _piId;
            this.state.initialValuesOrNull[`pridpiid${id}`] = _pridpiid;
        }
        // do the formik assignment here
        // update the formik so that the textfield shows the porzioGstProfileId eg: "DEV0010232323"
        this.fPropsDynamic.setFieldValue(_fieldconfig.mappedFieldNameLower, _piId ? _piId : "", false);

    }
    //#endregion

    extractRecipientInfo = (_fProps) => {
        let oRET = {};
        if (_fProps) {

            oRET["filename"] = "Manual Entry";//this.state.initialValuesOrNull?.transactionfilename;
            oRET["porziogstprofileid"] = _fProps.values["porziogstprofileid"];
            oRET["companyprofileid"] = _fProps.values["companyprofileid"];
            // affiliated company and PhysicalOwnership
            oRET["affiliatedcompanyid"] = _fProps.values["affiliatedcompanyid"];
            oRET["physicianownership"] = _fProps.values["physicianownership"];

            oRET["profiletypeid"] = _fProps.values["profiletypeid"];
            oRET["profilecategoryid"] = _fProps.values["recipientcategoryid"];
            oRET["organizationname"] = _fProps.values["organizationname"];
            oRET["suffix"] = _fProps.values["suffix"];
            oRET["lastname"] = _fProps.values["lastname"];
            oRET["middlename"] = _fProps.values["middlename"];
            oRET["firstname"] = _fProps.values["firstname"];
            oRET["prefix"] = _fProps.values["prefix"];
            oRET["credentialsid"] = _fProps.values["credentialsid"];

            oRET["usnpinumber"] = _fProps.values["usnpinumber"];
            oRET["uslicensestate"] = _fProps.values["uslicensestate"];
            oRET["usstatelicensenumber"] = _fProps.values["usstatelicensenumber"];
            oRET["ustaxidnumber"] = _fProps.values["ustaxidnum"];

            // no need to pass since they can be added via identifier tab only
            oRET["identifiercountry"] = _fProps.values["recipientidentifiercountryid"];
            oRET["identifiertypeid"] = _fProps.values["recipientidentifiertypeid"];
            oRET["identifiervalue"] = _fProps.values["recipientidentifiervalue"];
            oRET["identifierdescription"] = _fProps.values["recipientidentifierdescription"];

            oRET["specialityid"] = _fProps.values["specialityid"];
            oRET["specialtyid"] = _fProps.values["specialityid"];


            let addressCountryObj = { "countryId": "", "countryName": "" };
            if (_fProps.values["country"] > 0) {
                // console.log("COUNTRIES", LookupService._COUNTRIES);
                const countryObj = LookupService._COUNTRIES.find(c => c.id === _fProps.values["country"]);
                addressCountryObj.countryId = countryObj ? countryObj.id : "";
                addressCountryObj.countryName = countryObj ? countryObj.value : "";
            }
            oRET.addresslist = [
                ProfileTabService.getAddressObject(true, true, "", // primary & active
                    _fProps.values["address1"], _fProps.values["address2"], _fProps.values["address3"], _fProps.values["address4"],
                    _fProps.values["city"], _fProps.values["province"], _fProps.values["postalcode"],
                    "", "", // _porziogstaddressid & _companyaddressid
                    addressCountryObj
                )
            ];
            let recipientIdentifierTypeObj = { "lovId": 0, "lovKey": "", "ordinal": 0, "isdefault": false, "fieldName": "", "localLovKey": "" };
            // if (_fProps.values["recipientidentifiertypeid"] > 0) {
            //     // console.log("COUNTRIES", LookupService._COUNTRIES);
            //     //identifiertypelist = [LookupService.getIdentifierTypes(this.context.user.tenantId)]
            //     const identifierTypes = LookupService.getIdentifierTypes(this.context.user.tenantId);
            //     if (Array.isArray(identifierTypes)) {
            //         const identifierTypeObj = identifierTypes.filter(c => c.lovid === _fProps.values["recipientidentifiertypeid"]);
            //         recipientIdentifierTypeObj.lovId = identifierTypeObj ? identifierTypeObj.lovId : "";
            //         recipientIdentifierTypeObj.lovKey =  identifierTypeObj ? identifierTypeObj.lovKey : "";
            //         console.log(identifierTypeObj);
            //     } else {
            //     console.log('identifierTypes is not an array:', identifierTypes);
            //     }
            //    // const identifierTypeObj = LookupService.getIdentifierTypes(this.context.user.tenantId).filter(c => c.lovid === _fProps.values["recipientidentifiertypeid"]);              
            // }
            oRET.identifierlist = [
                ProfileTabService.getIdentifierObject(_fProps.values["recipientidentifiertypeid"],_fProps.values["recipientcompanyidentifierid"],
                _fProps.values["recipientidentifiercountryid"],addressCountryObj,_fProps.values["recipientidentifierdescription"],
                _fProps.values["recipientidentifiervalue"],recipientIdentifierTypeObj,
                null, null,
              
                 )
            ]

        }
        return oRET;
    }


    fPropsSourceSystem = null;
    setSourceSystemFormikProps = (_fPropsSourceSystem) => {
        this.fPropsSourceSystem = _fPropsSourceSystem;
        return <></>;
    }

    // render
    TAB_PERMISSIONS = RolePermissionService.TRANSACTION_DETAILS;
    render() {
        const { classes } = this.props;
        // set the props for parent's validation & post logic
        this.props.tabConfig.ref = this; // 1/4) required by parent component
        this.fPropsDynamic = null;

        switch (this.state.fetchSourceSystemsResult) {
            case ResultStatus.NOT_LOADED:
            case ResultStatus.LOADING:
                return <PageLoadingComponent small classes={classes} label="Loading Transaction Details" />;
            case ResultStatus.SAVING:
                return <PageLoadingComponent small classes={classes} label="Saving Transaction Details" />;
            case ResultStatus.LOADED:
            case ResultStatus.SUCCESS:
                return (
                    <>
                        {/* Profile Details Tab */}
                        <ProfileDetailDialogComponent inputAction={CrudAction.UPDATE} modalAgNode={{
                                "prid": this.state.profileIdForDetailsDialog,
                                "porziogstprofileid": this.state.gstProfileIdForDetailsDialog
                            }}
                            open={this.state.showProfileDetailDialog || false}
                            onClose={(_isModified) => {
                                this.setState({ showProfileDetailDialog: false, targetPidFieldConfig: {} })
                                // !!! DONOT change the recipient info if linked profile is edited.
                                // if (_isModified) { 
                                // this.generateRecipientInfo(this.state.profileIdForDetailsDialog);
                                // }
                            }}
                        />

                        {this.state.openSalesRepDialog ?
                            <Dialog open={this.state.openSalesRepDialog || false} scroll={true ? "paper" : "body"} maxWidth="md">
                                <SalesRepDetailsComponent
                                    open={this.state.openSalesRepDialog || false}
                                    onClose={() => this.setState({ openSalesRepDialog: false })}
                                    modalAgNode={this.state.salesRepIdForDetailsDialog}
                                    inputAction={CrudAction.UPDATE}
                                    refreshGrid={false}
                                />
                            </Dialog>
                            : null}

                        {/* Recipient Search Dialog Component */}
                        <Dialog open={this.state.showSearchRecipientDialog || false} fullWidth={true} maxWidth='lg' scroll={false ? "paper" : "body"}>
                            <RecipientSearchDialogComponent onClose={(selectedProfile) => {
                                if (DataService.hasValue(selectedProfile)) {
                                    this.generateRecipientInfo(selectedProfile["prid"]);
                                } else {
                                    this.setState({
                                        showSearchRecipientDialog: false, showPossibleMatchesDialog: false,
                                        showCreateNewProfileDialog: false, showConfirmCreateNewProfile: false,
                                    }); // just hide
                                }
                            }} />
                        </Dialog>

                        {/* PID Profile Search Dialog Component */}
                        <Dialog open={this.state.showPIDProfileSearchDialog || false} fullWidth={true} maxWidth='lg' scroll={false ? "paper" : "body"}>
                            <RecipientSearchDialogComponent title={"Search Profile"} onClose={(selectedProfile) => {
                                this.state.targetPidFieldConfig.clickHandled = false; // reset so the linkClickCanWork
                                if (DataService.hasValue(selectedProfile)) {
                                    this.setPiIDandPridpiID(this.state.targetPidFieldConfig, selectedProfile.porziogstprofileid, selectedProfile.prid,);
                                }
                                // finally close and clear
                                this.setState({ showPIDProfileSearchDialog: false, targetPidFieldConfig: {} })
                            }} />
                        </Dialog>

                        {/* SourceSystem  Dropdown*/}
                        <Formik initialValues={this.getSourceSystemInitialValues()} validationSchema={this.getSourceSystemValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }}>
                            {(fPropsSourceSystem) => (
                                <form>
                                    <Box style={{ paddingLeft: 24, paddingRight: 16, paddingTop: 8, paddingBottom: 8, backgroundColor: MatThemeService.getControllerBG() }}>
                                        {this.setSourceSystemFormikProps(fPropsSourceSystem)}
                                        {LayoutService.getDropDown((this.props.isReadOnly || !this.state.isNew), classes.dialogControl, classes.menuPaper, fPropsSourceSystem, this.sourceSystemValidationSchema, "sourceSystemId", "Source System",
                                            this.state.sourceSystemList, "id", "text", null, true, "48%",
                                            (_formikProps, _newSourceSystemId) => { this.onSourceSystemChange(_newSourceSystemId, false); })
                                        }
                                        {LayoutService.getTextBox(true, classes.dialogControl, fPropsSourceSystem, this.sourceSystemValidationSchema, "transactionfilename", "File Name", "string", "48%", null, null, null, "small", {},
                                            { InputLabelProps: { shrink: true } })}
                                    </Box>
                                </form>
                            )}
                        </Formik >
                        {/* Render Dynamic Controls */}
                        {this.renderDynamicControls(classes)}
                        {<Box style={{ paddingTop: 12, paddingBottom: 12 }} />}
                    </>
                );
            case ResultStatus.ERROR:
            default:
                return (<DialogErrorFragmentComponent classes={classes} description="Error Loading Transaction Details" onRetry={() => { this.fetchSourceSystems(); }} />);
        }
    }

    //#endregion

    //#region Dynamic controls

    // api
    onSourceSystemChange = (_newSourceSystemId, _force) => {
        this.oSubscriptions.cancelAll();

        if (_force || this.state.selSourceSystemId !== _newSourceSystemId) {
            if (DataService.hasValidValue(_newSourceSystemId)) {
                //CLEAR CAHCE etc,...
                TransactionTabService.INIT(this.state.isClone);
                DynamicControlService.CLEAR();
                this._setTemplateFetchState(ResultStatus.LOADING, _newSourceSystemId, null, null, () => {
                    this.oSubscriptions.add(
                        combineLatest([
                            LookupService.getTemplateBySourceIdAsOBS(this.context.user.tenantId, _newSourceSystemId, LookupService._FULL_USER_PREFERENCES_OBJ?.defaultLanguageId ?? 1), // get the template
                            // for new don't get the data, set it null
                            (this.state.isNew ? of(this.state.isClone ? this.state.cleanedTransactionToClone : null) : LookupService.getTransactionTabDetailsAsOBS(this.context.user.tenantId, this.props.modalAgNode.transactionid, this.props.porzioGstId)),
                            (this.state.isNew ? of(null) : ApiService.getOBS(API_ENDPOINT.CORE, `/Transactions/RelatedTransactions/${this.context.user.tenantId}/${this.context.user.userId}/${this.props.modalAgNode.transactionid}/1/5000`)),
                            TransactionTabService.getUserCurrencyAsOBS(this.context.user.userId),
                            TransactionTabService.fetchCurrenciesAsOBS(),
                            LookupService.getCountriesAsOBS(this.context),
                        ]).subscribe(
                            // success result
                            ([_templateData, _initialValuesOrNull, _relatedTransactions, _userCurrencyObject, _currencyList, _countryList]) => {
                                _relatedTransactions = Array.isArray(_relatedTransactions) ? _relatedTransactions : [];
                                this.setState({ relatedTransactions: _relatedTransactions });
                                LookupService._CURRENCIES = DataService.formatCurrencyList(LookupService._CURRENCIES);
                                if (_initialValuesOrNull) {
                                    _initialValuesOrNull["userpersonalcurrency"] = _userCurrencyObject.currencyNameFormatted;
                                    _initialValuesOrNull["country"] = LookupService._COUNTRIES.find(c => c.value === _initialValuesOrNull["country"]) ? _countryList.find(c => c.value === _initialValuesOrNull["country"]).id : 0;
                                    _initialValuesOrNull["recipientidentifiercountry"] = _initialValuesOrNull.recipientidentifiercountryid;
                                    _initialValuesOrNull["prorataamt"] = Number(_initialValuesOrNull.prorataamt).toFixed(2);
                                    _initialValuesOrNull["convertedcurrencytotalamount"] = "n/a - base or conversion currency not found";

                                    if (_currencyList.find(o => o.currencyid === _initialValuesOrNull.currencyid)) {
                                        this.oSubscriptions.add(
                                            TransactionTabService.getConvertedTotalAmountAsOBS(this.context.user.tenantId, _currencyList.find(o => o.currencyid === _initialValuesOrNull.currencyid).currencyAcronym, _userCurrencyObject.currencyAcronym, _initialValuesOrNull.totalamount, _initialValuesOrNull.transactiondate)
                                                .subscribe((_convertedTotalAmount) => {
                                                    if (!_convertedTotalAmount || _convertedTotalAmount === "") {
                                                        _initialValuesOrNull["convertedcurrencytotalamount"] = "n/a - conversion service not available";
                                                    } else if (DataService.isNullOrUndefined(_initialValuesOrNull.transactiondate)) {
                                                        _initialValuesOrNull["convertedcurrencytotalamount"] = "Transaction Date is required";
                                                    } else {
                                                        _initialValuesOrNull["convertedcurrencytotalamount"] = _convertedTotalAmount;
                                                    }
                                                    // get group fields map
                                                    const groupFieldsMap = TransactionTabService.getGroupFieldsMap(this, this.state.isNew, _templateData, "grouprenderid", "fieldID");
                                                    this._setTemplateFetchState(ResultStatus.LOADED, _newSourceSystemId, groupFieldsMap, _initialValuesOrNull, () => {
                                                        this.props.onTransactionTabLoaded(_initialValuesOrNull, _relatedTransactions);
                                                        // ToastService.showInfo(`${this.state.isNew ? "Template" : "Data"} Loaded`, 1000);
                                                        if (this.state.isClone) {
                                                            ToastService.showInfo(`Cloned from ${this.props.transactionToClone["porziogsttransactionid"]}`);
                                                        }
                                                    });
                                                }))
                                    } else {
                                        // get group fields map
                                        const groupFieldsMap = TransactionTabService.getGroupFieldsMap(this, this.state.isNew, _templateData, "grouprenderid", "fieldID");
                                        this._setTemplateFetchState(ResultStatus.LOADED, _newSourceSystemId, groupFieldsMap, _initialValuesOrNull, () => {
                                            this.props.onTransactionTabLoaded(_initialValuesOrNull, _relatedTransactions);
                                            // ToastService.showInfo(`${this.state.isNew ? "Template" : "Data"} Loaded`, 1000);
                                        });
                                    }
                                } else {
                                    // get group fields map
                                    const groupFieldsMap = TransactionTabService.getGroupFieldsMap(this, this.state.isNew, _templateData, "grouprenderid", "fieldID");
                                    this._setTemplateFetchState(ResultStatus.LOADED, _newSourceSystemId, groupFieldsMap, _initialValuesOrNull, () => {
                                        this.props.onTransactionTabLoaded(_initialValuesOrNull, _relatedTransactions);
                                        // passed from profileTransactions for add new
                                        if (this.props.preloadedRecipientProfileId > 0) {
                                            this.generateRecipientInfo(this.props.preloadedRecipientProfileId);
                                        }
                                    });
                                }
                            },
                            // on error
                            (err) => {
                                ToastService.showError(`Error Occured while Loading the ${this.state.isNew ? "Template" : "Data"}`);
                                this._setTemplateFetchState(ResultStatus.ERROR, _newSourceSystemId, null, null, () => { });
                            }
                        )
                    );
                });
            } else { this._setTemplateFetchState(ResultStatus.LOADED, "", null, null, () => { }); } // clear selectedSourceSystemId
        }
    }

    // render
    renderDynamicControls = (_classes) => {
        switch (this.state.templateFetchResult) {
            case ResultStatus.NOT_LOADED: return (<></>);
            case ResultStatus.LOADING: return (<PageLoadingComponent classes={_classes} label="Loading Dynamic Controls" />);
            // Good to Render the components
            case ResultStatus.LOADED:
            case ResultStatus.SUCCESS:
                if (!DataService.hasValidValue(this.state.selSourceSystemId)) {
                    return (<DialogErrorFragmentComponent small classes={_classes} description="Select the Source System" />);
                }
                else if (DataService.mapHasNoElements(this.state.allGroupFieldsMap)) {
                    return (<DialogErrorFragmentComponent small classes={_classes} description="Select a valid Source System"
                        onRetry={() => { this.onSourceSystemChange(this.state.selSourceSystemId, true); }} />);
                }
                else {
                    return (
                        <Formik initialValues={this.state.dynamicControlInitialValues} validationSchema={this.state.dynamicControlsValidationSchema}
                            validationSchemaOptions={{ showMultipleFieldErrors: true }} validateOnChange={false} validateOnBlur={false}>
                            {(_fPropsDynamic) => (
                                <form>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <>
                                            {this.renderData(_classes, _fPropsDynamic)}
                                            {!this.state.showTransactionStatusDialog ? null :
                                                <StatusOverrideComponenet currentValue={this.fPropsDynamic.values["transactionstatusid"]}
                                                    id={this.props.modalAgNode.transactionid}
                                                    entityId={ENTITY_TYPE.TRANSACTION}
                                                    onClose={(_reload) => {
                                                        this.setState({ showTransactionStatusDialog: false });
                                                    }}
                                                    refreshTab={() => {
                                                        if (this.props.setReadOnlyMode) {
                                                            this.props.setReadOnlyMode(true);
                                                        }
                                                        LookupService.fetchCommonLookups(this.context);
                                                        this.fetchSourceSystems();
                                                    }}
                                                />}
                                        </>
                                    </MuiPickersUtilsProvider>
                                </form>
                            )}
                        </Formik >
                    );
                }
            // Error
            case ResultStatus.ERROR:
            default:
                return (<DialogErrorFragmentComponent classes={_classes} description="Error loading Dynamic Controls"
                    onRetry={() => { this.onSourceSystemChange(this.state.selSourceSystemId, true); }} />);
        }
    }
    //#endregion


    renderData = (_classes, _fPropsDynamic) => {
        this.fPropsDynamic = _fPropsDynamic;
        var lastGroupIndex = 0;

        // 2/2) Map Errors
        DynamicControlService.setErrors(_fPropsDynamic, this.state.initialValuesOrNull);

        // filter, static & custom fields
        const oRET = [
            TransactionTabService.filterFieldGroupKey,
            TransactionTabService.staticFieldGroupKey,
            RolePermissionService.TRANSACTION_RECIPINET_INFORMATION.cannotView ? -1 : TransactionTabService.recipientInformationFieldGroupKey,
        ].filter((_mapKey) => this.state.dynamicControlFieldsMap.get(_mapKey)?.fieldConfigs.length > 0 // empty check
        ).map((_mapKey, _groupIndex) => {
            lastGroupIndex = _groupIndex;
            const groupInfo = this.state.dynamicControlFieldsMap.get(_mapKey);
            return (
                <React.Fragment key={`group${_groupIndex}`}>

                    {/* Possible Matches Dialog Component */}
                    <Dialog open={this.state.showPossibleMatchesDialog || false} fullWidth={true} maxWidth='lg' scroll={false ? "paper" : "body"}>
                        <PossibleMatchesDialogComponent recordId={"" + this.props.modalAgNode.recordid} entityId={ENTITY_TYPE.TRANSACTION}
                            unmatchedProfileObj={this.getUnmatchedProfileObj(_fPropsDynamic)}
                            onClose={(selectedProfile) => {
                                if (DataService.hasValue(selectedProfile)) {
                                    this.generateRecipientInfo(selectedProfile["prid"]);
                                } else {
                                    this.setState({ showSearchRecipientDialog: false, showPossibleMatchesDialog: false, showCreateNewProfileDialog: false }); // just hide
                                }
                            }} />
                    </Dialog>

                    {/* Confirm New Profile dialog */}
                    {!this.state.showConfirmCreateNewProfile ? null :
                        <Dialog open={this.state.showConfirmCreateNewProfile || false} fullWidth={true} maxWidth='md' scroll={false ? "paper" : "body"}>
                            {/* Title */}
                            <DialogTitle style={{ padding: 0 }} id="dialog-title">
                                <AppBar position="static">
                                    <Toolbar>
                                        <Typography variant="h6" className={_classes.root}>Create New Profile Confirmation</Typography>
                                        {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", () => { this.setState({ showConfirmCreateNewProfile: false }) }, "secondary", "keyActionCancel1")}
                                        {LayoutService.getIconButton(false, MatIconService.OK, "Confirm", () => {
                                            this.setState({ showConfirmCreateNewProfile: false, showCreateNewProfileDialog: true, });
                                        }, "inherit", "keyActionSave")}
                                    </Toolbar>
                                </AppBar>
                            </DialogTitle>
                            {/* Content */}
                            <DialogContent style={{ padding: 24 }}>
                                <h4>This record will be inserted as a new Profile. This action cannot be reversed.</h4>
                            </DialogContent>
                        </Dialog>
                    }

                    {/* Add New Profile Contained-Dialog as a Page */}
                    {!this.state.showCreateNewProfileDialog ? null :
                        <ProfileDetailDialogComponent inputAction={CrudAction.CREATE} standAloneProps={!this.props.fromGlobalSearch ? LayoutService.getContainedDialogProps(false) : null} modalAgNode={{}}
                            recipientProfileInfo={this.extractRecipientInfo(_fPropsDynamic)}
                            open={this.state.showCreateNewProfileDialog || false}
                            onClose={(_result) => {
                                this.setState({ showCreateNewProfileDialog: false });
                                if (_result && _result !== false && _result.profileId > 0) {
                                    this.generateRecipientInfo(_result.profileId);
                                } else {
                                    this.setState({ showSearchRecipientDialog: false, showPossibleMatchesDialog: false, showCreateNewProfileDialog: false }); // just hide
                                }
                            }}
                        />
                    }

                    <Box key={`section${_groupIndex}`} style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 8, paddingBottom: 16, backgroundColor: MatThemeService.getReverseAlternatingBG(lastGroupIndex) }}>
                        {DataService.isStringNullOrEmpty(groupInfo.name) ? null :
                            _mapKey !== TransactionTabService.recipientInformationFieldGroupKey ?
                                <Typography key={`sectionHeader${_groupIndex}`} variant="h6" className={_classes.sectionHeader} style={{ margin: 8 }}>{groupInfo.name}</Typography>
                                :
                                // Recipient Information - header icons
                                <Grid key={`sectionHeaderGrid${_groupIndex}`} container direction="row" justify="space-between" alignItems="center">
                                    <Typography variant="h6" className={_classes.sectionHeader} style={{ margin: 8 }}>{groupInfo.name}</Typography>
                                    {this.isRecipientMatched ? null : <Typography style={{ paddingLeft: 8, paddingRight: 8, backgroundColor: "#FF8A8A", color: "#6E0101" }} variant="h6" align="center">Unmatched Recipient</Typography>}
                                    <Grid key={`sectionHeaderRecipientGrid${_groupIndex}`}>
                                        {/* Create and Link New Recipient Icon */}
                                        {this.isRecipientMatched || RolePermissionService.TRANSACTION_RECIPINET_INFORMATION.cannotCreate ? null
                                            : LayoutService.getIconButton(this.props.isReadOnly, MatIconService.ADD_CIRCLE_OUTLINE, "Create new Recipient", () => {
                                                this.setState({ showConfirmCreateNewProfile: true })
                                            }, "secondary")}
                                        {/* PossibleMatches Icon */}
                                        {this.isRecipientMatched || this.state.isClone || this.props.preloadedRecipientProfileId > 0 || RolePermissionService.TRANSACTION_RECIPINET_INFORMATION.cannotEdit ? null
                                            : LayoutService.getIconButton(this.props.isReadOnly, MatIconService.IDEA_BULB_32, "Manual Match Center", () => {
                                                this.setState({ showPossibleMatchesDialog: true })
                                            }, "inherit", "", "bottom", { color: this.props.isReadOnly ? null : "#F57C00" })}
                                        {/* Manually Search Profile Icon */}
                                        {this.props.preloadedRecipientProfileId > 0 || RolePermissionService.TRANSACTION_RECIPINET_INFORMATION.cannotEdit ? null // preloadedRecipientProfileId -> received from profileTransactions
                                            : LayoutService.getIconButton(this.props.isReadOnly, MatIconService.SEARCH, "Search", () => {
                                                this.setState({ showSearchRecipientDialog: true })
                                            }, "secondary", "", "bottom", { fontSize: 30, marginTop: 4 })}
                                    </Grid>
                                </Grid>
                        }
                        <>
                            {_mapKey === TransactionTabService.recipientInformationFieldGroupKey && this.state.isRecipientInfoInProgress ? <LinearProgress color="secondary" /> : null}
                            {_mapKey === TransactionTabService.recipientInformationFieldGroupKey && this.isRecipientMatched ? <TextField InputLabelProps={{ shrink: true }} disabled fullWidth multiline variant="outlined" value={this.fPropsDynamic.values["matchKey"]} label="Recipient Match Description" /> : null}
                            {
                                // Recipient Information or Other - controls
                                groupInfo.fieldConfigs.map((_fconfig, _fieldIndex) => {
                                    let _control = DynamicControlService.getControl(
                                        //false,
                                        _mapKey === TransactionTabService.recipientInformationFieldGroupKey ? true : this.props.isReadOnly, // recipientcontrols should always be readonly
                                        _classes, _fPropsDynamic, this.state.initialValuesOrNull, this.state.dynamicControlsValidationSchema, _fieldIndex, _fconfig);
                                    if (_fconfig.secondaryActionIcon) {
                                        _control = (
                                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                {_control}
                                                {LayoutService.getIconButton(this.props.isReadOnly, _fconfig.secondaryActionIcon, _fconfig.secondaryActionTooltip, _fconfig.onSecondaryActionClick, "primary", `secondaryAction${_fconfig.fieldID}`)}
                                            </Grid>
                                        );
                                    }
                                    return _control;
                                })
                            }
                        </>
                    </Box>
                    <Divider />
                </React.Fragment>
            );
        });

        // engagement & venue, PI etc,... controls
        // if (_fPropsDynamic.values["engagementrelated"] === true) {
        oRET.push([
            TransactionTabService.ownershipInformationFieldGroupKey,
            TransactionTabService.engagementInformationFieldGroupKey,
            TransactionTabService.venueInformationFieldGroupKey,
            TransactionTabService.researchInformationFieldGroupKey,
            TransactionTabService.PIInformationFieldGroupKey,
            TransactionTabService.customFieldGroupKey,
            TransactionTabService.otherFieldGroupKey,
        ]
            .filter((_mapKey) => this.state.dynamicControlFieldsMap.get(_mapKey).fieldConfigs.length > 0 // empty check
            ).map((_mapKey, _groupIndex) => {
                lastGroupIndex = _groupIndex;
                const groupInfo = this.state.dynamicControlFieldsMap.get(_mapKey);
                return (
                    <React.Fragment key={`eng_group${_groupIndex}`}>
                        <Box key={`eng_section${_groupIndex}`} style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 8, paddingBottom: 16, backgroundColor: MatThemeService.getReverseAlternatingBG(lastGroupIndex) }}>
                            {DataService.isStringNullOrEmpty(groupInfo.name) ? null : <Typography key={`eng_sectionHeader${_groupIndex}`} variant="h6" className={_classes.sectionHeader} style={{ margin: 8 }}>{groupInfo.name}</Typography>}
                            {
                                groupInfo.fieldConfigs.map((_fconfig, _fieldIndex) => {
                                    return DynamicControlService.getControl(this.props.isReadOnly, _classes, _fPropsDynamic, this.state.initialValuesOrNull, this.state.dynamicControlsValidationSchema, _fieldIndex, _fconfig);
                                })
                            }
                        </Box>
                        <Divider />
                    </React.Fragment>
                );
            })
        );
        // }

        // RETURN
        return oRET;
    }


    //#region  Utils
    _getGroup = (_header, _group, _gridUtils, _rowData, _columnDefsCallback) => {
        return {
            "header": _header,
            "group": _group,
            "gridUtils": _gridUtils,
            "rowData": _rowData,
            "columnDefs": _columnDefsCallback(this, this.props.isReadOnly, _gridUtils, _group.fieldConfigs),
            "frameworkComponents": _gridUtils.frameworkComponents,
            "setGridParamsCallback": _gridUtils.setGridParams,
        };
    }

    _setTemplateFetchState = (_templateFetchResult, _sourceSystemId, _groupFieldsMap, _initialValuesOrNull, _callback = () => { }) => {

        // 1/2) parse error fileds
        _initialValuesOrNull = DynamicControlService.parseErrorFields(_initialValuesOrNull);
        _initialValuesOrNull = DynamicControlService.parseCustomFields(_initialValuesOrNull);

        const _dynamicControlFieldsMap = DataService.getMapByKeys(_groupFieldsMap,
            [TransactionTabService.filterFieldGroupKey, TransactionTabService.staticFieldGroupKey, TransactionTabService.recipientInformationFieldGroupKey,
            TransactionTabService.ownershipInformationFieldGroupKey, TransactionTabService.engagementInformationFieldGroupKey, TransactionTabService.venueInformationFieldGroupKey,
            TransactionTabService.researchInformationFieldGroupKey, TransactionTabService.PIInformationFieldGroupKey,
            TransactionTabService.customFieldGroupKey, TransactionTabService.otherFieldGroupKey
            ]);

        this.isRecipientMatched = DataService.isNullOrUndefined(_initialValuesOrNull) ? false : _initialValuesOrNull["isrecipientmatch"];

        if (this.fPropsSourceSystem) {
            if (_initialValuesOrNull) {
                this.fPropsSourceSystem.setFieldValue("transactionfilename", _initialValuesOrNull?.transactionfilename);
            } else if (this.state.isNew) {
                this.fPropsSourceSystem.setFieldValue("transactionfilename", "Manual Entry");
            }
        }

        // prepare the piidMap
        let _piIdMap = new Map();
        let _prIdpiIdMap = new Map();
        _piIdMap.set("piid1", _initialValuesOrNull?.piid1);
        _prIdpiIdMap.set("pridpiid1", _initialValuesOrNull?.pridpiid1);
        _piIdMap.set("piid2", _initialValuesOrNull?.piid2);
        _prIdpiIdMap.set("pridpiid2", _initialValuesOrNull?.pridpiid2);
        _piIdMap.set("piid3", _initialValuesOrNull?.piid3);
        _prIdpiIdMap.set("pridpiid3", _initialValuesOrNull?.pridpiid3);
        _piIdMap.set("piid4", _initialValuesOrNull?.piid4);
        _prIdpiIdMap.set("pridpiid4", _initialValuesOrNull?.pridpiid4);
        _piIdMap.set("piid5", _initialValuesOrNull?.piid5);
        _prIdpiIdMap.set("pridpiid5", _initialValuesOrNull?.pridpiid5);

        // insert match key
        let _dynamicControlInitialValues = DynamicControlService.getIntitialValues(this.state.isNew, _dynamicControlFieldsMap, _initialValuesOrNull);
        if (_dynamicControlInitialValues && _initialValuesOrNull) {
            // _initialValuesOrNull["matchKey"] = "First, Last, Middle-b, City, Country, Province-b, Recipient Identifier Type, Recipient Identifier Value, Postal Code";
            _dynamicControlInitialValues["matchKey"] = _initialValuesOrNull["matchKey"];
        }

        this.setState({
            prid: _initialValuesOrNull?.prid,
            porziogstprofileid: _initialValuesOrNull?.porziogstprofileid,
            profileId: _initialValuesOrNull ? _initialValuesOrNull.prid : null,
            profileIdForDetailsDialog: _initialValuesOrNull ? _initialValuesOrNull.prid : null, // set via setLinkPropertiesIfAny() for different types of Profiles

            selSourceSystemId: _sourceSystemId,
            templateFetchResult: _templateFetchResult,
            initialValuesOrNull: _initialValuesOrNull,
            piIdMap: _piIdMap,
            prIdpiIdMap: _prIdpiIdMap,
            allGroupFieldsMap: _groupFieldsMap,
            dynamicControlFieldsMap: _dynamicControlFieldsMap,
            dynamicControlInitialValues: _dynamicControlInitialValues,
            dynamicControlsValidationSchema: DynamicControlService.getValidationSchema(_dynamicControlFieldsMap)
        }, _callback);
    }
    //#endregion

    fPropsDynamic = null;
    validationCallback = () => {
        var oSubject = new ReplaySubject(); // 1st

        // static/async validation logic should trigger the below
        oSubject.next(true); // true <- success

        // return the subject as observable
        return oSubject.asObservable(); // 3rd
    }


    /** 1/3 Required */
    isDirtyCallback = () => {
        // do any additional checkings if needed
        if (this.fPropsDynamic) {
            return this.fPropsDynamic.dirty;
        } else {
            return false;
        }
    }
    /** 2/3 Required in Parent */
    resetCallback = (_updateFormWithNewValues = false) => {
        // modified on 02-03-2022 as per ticket PP2-1241
        if (this.fPropsDynamic) {
            if (_updateFormWithNewValues) {
                this.fPropsDynamic.resetForm({ values: { ...this.fPropsDynamic.values } });
            }
            else {
                this.fPropsDynamic.resetForm();
            }
        }
        // do any additional resetting if needed
    }

    /** 3/3 Required in Parent */
    isRecipientMatched = false;
    postCallbackOBS = () => {
        if (DataService.isNullOrUndefined(this.fPropsDynamic) || this.fPropsDynamic.isSubmitting) {
            return of(null);
        }
        else {
            var oReturnSubject = new ReplaySubject(); // 1st
            var oValidationSubject = new ReplaySubject(); // 2nd

            this._validate(oValidationSubject);
            oValidationSubject.asObservable().subscribe((x) => {
                if (x.currentTransactionData) {
                    ApiService.postOBS(API_ENDPOINT.CORE, "/Transactions/SaveTransaction", JSON.stringify(x))
                        .subscribe(
                            (_successResult) => {
                                ToastService.showSuccess("Transaction Saved");
                                oReturnSubject.next(_successResult);
                            },
                            (_errorResult) => {
                                ToastService.showError("Error occured while saving");
                                oReturnSubject.next("save_error");
                            }
                        );

                } else {
                    ToastService.showWarning("Please recheck your Input");
                    oReturnSubject.next("validation_error");
                }
            });

            // return the subject as observable
            return oReturnSubject.asObservable(); // 3rd
        }
    }

    _validate = async (_oSubject) => {
        // if (!this.state.isNew) {
        //     _oSubject.next(this._getDataToPost());
        // } else {
        //     await this.state.dynamicControlsValidationSchema.validate(this.fPropsDynamic.values, { abortEarly: false })
        //         .then((x) => {
        //             _oSubject.next(this._getDataToPost());
        //         })
        //         .catch((erroObj) => {
        //             if (erroObj.inner) { erroObj.inner.forEach(err => { this.fPropsDynamic.setFieldError(err.path, err.message); }); }
        //             _oSubject.next(null); // error
        //         });
        // }
        _oSubject.next(this.getDataToPost());
    }

    getDataToPost = () => {
        //---Mandatory-Fields---------------
        var dataToPost = {};

        //---Filter/Static/Enagagement/Venue - Fields---------------
        const controlFieldConfigs = [
            ...this.state.dynamicControlFieldsMap.get(TransactionTabService.filterFieldGroupKey).fieldConfigs,
            ...this.state.dynamicControlFieldsMap.get(TransactionTabService.staticFieldGroupKey).fieldConfigs,
            ...this.state.dynamicControlFieldsMap.get(TransactionTabService.recipientInformationFieldGroupKey).fieldConfigs,
            ...this.state.dynamicControlFieldsMap.get(TransactionTabService.ownershipInformationFieldGroupKey).fieldConfigs,
            ...this.state.dynamicControlFieldsMap.get(TransactionTabService.engagementInformationFieldGroupKey).fieldConfigs,
            ...this.state.dynamicControlFieldsMap.get(TransactionTabService.venueInformationFieldGroupKey).fieldConfigs,
            ...this.state.dynamicControlFieldsMap.get(TransactionTabService.researchInformationFieldGroupKey).fieldConfigs,
            ...this.state.dynamicControlFieldsMap.get(TransactionTabService.PIInformationFieldGroupKey).fieldConfigs,
            ...this.state.dynamicControlFieldsMap.get(TransactionTabService.otherFieldGroupKey).fieldConfigs,

        ];
        // push the engagement related section only if it is required
        // if (this.fPropsDynamic.values["engagementrelated"] === true) {
        controlFieldConfigs.push(...this.state.dynamicControlFieldsMap.get(TransactionTabService.engagementInformationFieldGroupKey).fieldConfigs);
        controlFieldConfigs.push(...this.state.dynamicControlFieldsMap.get(TransactionTabService.venueInformationFieldGroupKey).fieldConfigs);
        // }

        // recipientInformation
        const preloadedRecipientProfileId = this.fPropsDynamic.values["porziogstprofileid"];
        if (DataService.hasValidValue(preloadedRecipientProfileId)) {
            dataToPost['porziogstprofileid'] = preloadedRecipientProfileId;
            dataToPost['isrecipientmatch'] = this.isRecipientMatched;
        }

        // populate matchKey
        dataToPost['matchKey'] = this.fPropsDynamic.values["matchKey"];

        // populate the dataToPost
        let updatedFieldsForRelatedTransactions = {};
        let hasRelatedTransactionUpdates = false;
        controlFieldConfigs.forEach(fieldConfig => {
            const _mappedFieldName = DynamicControlService.getMappedFieldName(fieldConfig) + "";
            const _oldValue = this.state.isNew ? null : this.state.initialValuesOrNull[_mappedFieldName]; // 0
            const _newValue = this.fPropsDynamic.values[_mappedFieldName];  // ""
            const formattedValue = TransactionTabService.convertForPost(dataToPost, fieldConfig, _mappedFieldName, _newValue);

            if (!this.state.isNew && TransactionTabService.groupExpensesFieldIds.includes(fieldConfig.fieldID) && _oldValue !== _newValue
                && !((_oldValue === undefined || _oldValue === null || _oldValue === 0) && _newValue === "") // this should be considerd as no change
            ) {
                hasRelatedTransactionUpdates = true;
                updatedFieldsForRelatedTransactions[_mappedFieldName] = dataToPost[_mappedFieldName];
                if (formattedValue.isLOV) {
                    updatedFieldsForRelatedTransactions[formattedValue.lovTextKey] = formattedValue.lovTextValue;
                }
            }
        });

        //--- PIID (1|2|3|4|5)------------------
        // piId key will be created with the initial or updated value from the map
        this.state.piIdMap.forEach((_value, _key) => { dataToPost[_key] = _value; });
        //--- PRIDPIID (1|2|3|4|5)------------------
        this.state.prIdpiIdMap.forEach((_value, _key) => { dataToPost[_key] = _value; });

        //---Custom-Fields---------------
        dataToPost.customfields = this.state.dynamicControlFieldsMap.get(TransactionTabService.customFieldGroupKey).fieldConfigs.map(fieldConfig => {
            var _fieldValue = this.fPropsDynamic.values[DynamicControlService.getMappedFieldName(fieldConfig)];
            if (fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.LOV) {
                _fieldValue = _fieldValue > 0 ? _fieldValue : 0;
                const lovObj = DataService.getFirstOrDefault(fieldConfig.customLOVList.filter((x) => x.lovId === _fieldValue));
                return { 'FIELDID': fieldConfig.fieldID, 'FIELDNAME': fieldConfig.fielD_ALIASNAME, 'FIELDVALUE': _fieldValue, 'FIELDLOVKEY': lovObj ? lovObj.lovKey : "" };
            } else if (fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.DATE_TIME || fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.DATE) {
                return { 'FIELDID': fieldConfig.fieldID, 'FIELDNAME': fieldConfig.fielD_ALIASNAME, 'FIELDVALUE': _fieldValue ? DataService.formatDate(new Date(_fieldValue)) : null };
            } else {
                return { 'FIELDID': fieldConfig.fieldID, 'FIELDNAME': fieldConfig.fielD_ALIASNAME, 'FIELDVALUE': _fieldValue };
            }
        });
        dataToPost.customfields = DataService.arrayToSingleQuotedJsonString(dataToPost.customfields, "");

        //--- ensure mandatory fields
        dataToPost.prid = this.state.prid;
        dataToPost.tenantid = this.context.user.tenantId;
        dataToPost.userId = this.context.user.userId;
        dataToPost.userType = this.context.user.userTypeId;
        dataToPost.uid = this.context.user.uid;
        dataToPost.transactionid = this.state.isNew ? 0 : this.state.selTransactionId;
        dataToPost.sourceid = this.state.selSourceSystemId;
        dataToPost.recordid = this.state.initialValuesOrNull ? this.state.initialValuesOrNull.recordid : null;
        dataToPost.fileid = this.state.initialValuesOrNull ? this.state.initialValuesOrNull.fileid : null;
        dataToPost.ismanuallyentered = true; //this.state.initialValuesOrNull ? this.state.initialValuesOrNull.ismanuallyentered : 1;
        //---

        // return
        return {
            'currentTransactionData': dataToPost,
            'hasRelatedTransactionUpdates': hasRelatedTransactionUpdates,
            'relatedTransactionIds': this.state.relatedTransactions.map(x => x.TransactionId),
            'updatedFieldsForRelatedTransactions': updatedFieldsForRelatedTransactions
        };

    }



    //---
}
export default LayoutService.getHocComponenet(TransactionTabComponent);