import React, { Component } from "react";
import { Dialog, DialogTitle, DialogContent, Box, Typography, AppBar, Toolbar, DialogContentText, Grid, LinearProgress, FormControl, InputLabel, Select, MenuItem, Chip, Divider, FormHelperText } from "@material-ui/core";
import { combineLatest } from "rxjs";
import { AllEnterpriseModules } from "ag-grid-enterprise";
import { API_ENDPOINT } from "../../shared/types/enums";
import { AuthContext } from "../../shared/store/authProvider";
import ChildMessageRendererComponent from "./childMessageRendererComponent";
import PageHeaderComponent from "../../shared/components/page/pageHeaderComponent";
import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../shared/components/page/pageErrorComponent";
import { DataService, SubscriptionArray } from "../../shared/services/dataService";
import { CrudAction, ResultStatus, ENTITY_TYPE } from "../../shared/types/enums";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import LayoutService from "../../shared/services/layoutService";
import TransactionExpandedViewComponent from "./expanded-view/transactionsExpandedViewComponent.jsx";
import TransactionCenterService from "./transactionCenterService";
import TransactionDetailDialogComponent from "./transaction-detail/transactionDetailDialogComponent";
import DeleteFileComponent from "../../shared/components/dialog/deleteFileComponent";
import RerunValidationComponent from "./action-menu/rerunValidationComponent";
import LookupService from "../../shared/services/lookupService";
import RerunUnmatchQueueComponent from "./action-menu/rerunUnmatchQueueComponent";
import AgGridActionCellRendererComponent from "../../shared/components/ag-grid/agGridActionCellRendererComponent";
import AddEditCommentComponent from "../../shared/components/add-edit-comments/addEditCommentComponent";
import CommentAvailableImageRenderer from "../../shared/components/add-edit-comments/commentAvailableImageRenderer";
import AgGridIconTextRendererComponent from "../../shared/components/ag-grid/agGridIconTextRendererComponent";
import RolePermissionService from "../../shared/role-permissions/rolePermissionService";
import PageDynamicHeaderComponent from "../../shared/components/page/pageDynamicHeaderComponent";
import { MatIconService } from "../../shared/services/theme/matIconService";
import TransactionSearchDialogComponent from "./action-menu/transaction-search/transactionSearchDialogComponent";
import MatThemeService from "../../shared/services/theme/matThemeService";
import ToastService from "../../shared/services/toastService";
import ApiService from "../../shared/services/apiService";

class transactionCenterComponent extends Component {
  oSubscriptions = new SubscriptionArray();
  static contextType = AuthContext;
  STORE = TransactionCenterService;

  constructor(props) {
    super(props);
    this.state = {
      modules: AllEnterpriseModules,
      data: [],
      fileID: 0,
      col: "",
      fetchResult: ResultStatus.NOT_LOADED,
      frameworkComponents: {
        commentIconTextRenderer: AgGridIconTextRendererComponent,
        actionCellRendererComponent: AgGridActionCellRendererComponent,
        childMessageRendererComponent: ChildMessageRendererComponent,
        commentAvailableImageRenderer: CommentAvailableImageRenderer,
      },

      showTransactionExpandedDialog: false,
      showTransactionDetailDialog: false,
      showTransactionSearchDialog: false,
      openCommentFile: false,
      openDeleteFile: false,
      openRerunConfirm: false,
      openAddToRerunValidationQueueConfirm: false,
      openAddToRerunForUnmatchQueueConfirm: false,
      agCellData: null,
    };
  }
  componentWillUnmount() { this.oSubscriptions.cancelAll(); }
  componentDidMount() { this.fetchData()}

 
  fetchData = (_ignoreCache = true) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, dataFromFirstAPI: [], dataFromSecondAPI: [] });
 
  
    this.oSubscriptions.add(
      combineLatest([
        this.STORE.getObs(_ignoreCache, this.context), 
        LookupService.getSourceSystemsByEntityAsOBS(this.context.user.tenantId, ENTITY_TYPE.PROFILE)
      ]).subscribe({
        next: ([_data, _profileSourceList]) => {
          // Process the data from the first API
          if (!Array.isArray(_data)) { _data = [_data]; }
          _data.forEach((tran) => {
            if (tran.transactionFromDate !== undefined) {
              tran.transactionRange = tran.transactionFromDate + "--" + tran.transactionToDate;
            }
          });
          this.STORE.CACHE.SET(_data, 0);
          this.setState({ data: _data });
  
          // Process the data from the second API
          if (!Array.isArray(_profileSourceList)) { _profileSourceList = [_profileSourceList]; }
        
          this.setState({ profileSourceList: _profileSourceList });
  
          // Mark fetch as successful only once both APIs have returned data
          this.setState({ fetchResult: ResultStatus.SUCCESS });
        },
        error: (error) => {
          console.log("Error: " + error);
          // onResultStatus.ERROR
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      })
    );
  };

  methodFromParent = (cell, node) => {
    let _healthIndicator = null;
    switch (cell) {
      case ("error"): _healthIndicator = 1; break;
      case ("warning"): _healthIndicator = 2; break;
      case ("unmatched"): _healthIndicator = 3; break;
      case ("eligible"): _healthIndicator = 4; break;
      case ("nonLicensed"): _healthIndicator = 5; break;
      default: _healthIndicator = null;
    }
    if (RolePermissionService.TRANSACTION_EXPANDED_VIEW.cannotView) {
      RolePermissionService.showAccessDeniedToast();
    } else if (
      (_healthIndicator === 1 && node['error'] === 0) ||
      (_healthIndicator === 2 && node['warning'] === 0) ||
      (_healthIndicator === 3 && node['unmatched'] === 0) ||
      (_healthIndicator === 4 && node['eligible'] === 0) ||
      (_healthIndicator === 5 && node['nonLicensed'] === 0)
    ) {
      ToastService.showWarning('No data found.!');
      return;
    }
    else {
      this.setState({
        healthIndicator: _healthIndicator,
        fileID: node.fileID,
        col: cell,
        modalAgNode: node,
        showTransactionExpandedDialog: true,
      });
    }
  };

  // these are called by the triple-dot Action Menu
  handleAddEditCommentFile = (_cellRef) => {
    this.setState({
      openCommentFile: true,
      agCellData: _cellRef.props.data,
    });
  }
  handleDeleteFile = (_cellRef) => {
    this.setState({
      openDeleteFile: true,
      agCellData: _cellRef.props.data,
    });
  }
  handleRerunValidationConfirm = (_cellRef) => {
    this.setState({
      openRerunConfirm: true,
      agCellData: _cellRef.props.data,
    });
  }
  handleAddToRerunFileValidationQueueConfirm = (_cellRef) => {
    this.setState({
      openAddToRerunValidationQueueConfirm: true,
      agCellData: _cellRef.props.data,
    });
  }

  handleAddToRerunForUnmatchedQueueConfirm = (_cellRef) => {
    this.setState({
      //openAddToRerunForUnmatchedQueueConfirm: true,
      showConfirmCreateBatchProfileDialog: true,
      agCellData: _cellRef.props.data,
    });
  }

  createBatchProfile = () => {
    if (!DataService.isValidNumber(this.state.selProfileSourceId)) {
      ToastService.showWarning("Please select a Profile Source.");
    } else {
      this.setState({ isCreatingBatchProfile: true, });
      ApiService.getOBS(API_ENDPOINT.CORE, `/File/Unmatched/${this.context.user.tenantId}/${this.state.agCellData.sourceID}/${this.state.selProfileSourceId}/${this.context.user.userId}/${this.state.agCellData.fileID}`)
        .subscribe(
          (successObj) => {
            const objAsString = JSON.stringify(successObj.result);
            if (objAsString.includes("success")) {
              ToastService.showSuccess("File successfully queued for Profile Creation.");
              this.setState({
               isCreatingBatchProfile: false,
                showConfirmCreateBatchProfileDialog: false,
                selProfileSourceId: ''
              });
            } 
            else {
              const messageWithoutQuotes = successObj.result.replace(/["]/g, "");
              ToastService.showError(messageWithoutQuotes);
              //this.setState({ fetchResult: ResultStatus.ERROR });
              //this.props.handleClose();
              this.setState({
                isCreatingBatchProfile: false,
                 showConfirmCreateBatchProfileDialog: false,
                 selProfileSourceId: ''
               });
          }
          },
          (errorObj) => {
            ToastService.showError("An Error occured.");
            this.setState({ isCreatingBatchProfile: false });
          }
        );
    }
  }

  handleHide = () => { this.setState({ showTransactionDetailDialog: false, showTransactionExpandedDialog: false }); }
  onAddClick = (_transactionToClone = null) => {
    this.setState({
      transactionToClone: _transactionToClone,
      showTransactionDetailDialog: true,
      showTransactionExpandedDialog: false
    });
  }

  onDownloadClick = () => {
    let api = this.gridApi, params = this.getParams();
    api.exportDataAsExcel(params);
  }

  getParams() {
    return {
      // allColumns: true,
      columnKeys: this.gridColumnApi.getAllColumns().filter(c => c.colDef.headerName !== ""),
      fileName: `Transactions ${new Date().toDateString()}`
    };
  }
  onSearchClick = () => {
    this.setState({ showTransactionSearchDialog: true });
  }

  render() {
    const { classes } = this.props;

    if (RolePermissionService.TRANSACTION_CENTER.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes);
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
        case ResultStatus.SAVING:
          return (<PageLoadingComponent classes={classes} label="Loading Transactions" />);
        case ResultStatus.SUCCESS:
          return (
            <div id="MainTransactionGrid">
              <div style={{ backgroundColor: "#ddd", textAlign: "right" }}></div>
              <div className="IconBg marginTop10px tableTitleHead">
                {/* Header Componenet */}
                {<PageDynamicHeaderComponent classes={classes} label="Transactions" rightPadding={16}
                  leftActions={[
                    { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchData(true); }, },
                  ]}
                  rightActions={[
                    { icon: MatIconService.DOWNLOAD, title: "Download to Excel", iconColor: "primary", onClick: this.onDownloadClick, },
                    { icon: MatIconService.SEARCH, title: "Search", iconColor: "primary", onClick: this.onSearchClick, },
                    { icon: MatIconService.ADD_CIRCLE_OUTLINE, title: "Add New", iconColor: "secondary", onClick: this.onAddClick, isReadOnly: RolePermissionService.TRANSACTION_DETAILS.cannotCreate },
                  ]}
                />}

              </div>
              <div {...LayoutService.getAgGridStyles(160)}>
                <AgGridReact
                  columnDefs={this.STORE.getColumnDefs(this)}
                  rowData={this.state.data}
                  pagination={true}
                  paginationPageSize={100}
                  frameworkComponents={this.state.frameworkComponents}
                  sideBar={true}
                  gridOptions={{
                    headerHeight: 48,
                    tooltipShowDelay: 0,
                    suppressContextMenu: true,
                    context: { componentParent: this },
                  }}
                  onGridReady={(event) => {
                    this.gridApi = event.api;
                    this.gridColumnApi = event.columnApi;
                    event.api.closeToolPanel();
                  }}
                ></AgGridReact>
              </div>

              {/* ExpandedView Dialog rendered as Page */}
              <Dialog {...LayoutService.getContainedDialogProps()} open={this.state.showTransactionExpandedDialog || false}>
                <TransactionExpandedViewComponent inputAction={CrudAction.NONE} modalAgNode={this.state.modalAgNode}
                  fileID={this.state.fileID} column={this.state.col}
                  healthIndicator={this.state.healthIndicator} screenId={39}
                  onClose={(_reloadRequired) => {
                    this.handleHide();
                    if (_reloadRequired) { this.fetchData(true); }
                  }}
                  // clone stuff
                  onClone={(_transactionToClone) => {
                    this.handleHide();
                    this.onAddClick(_transactionToClone);
                  }}
                />
              </Dialog>

              {/* Transaction Dialog as a page On AddNew Click */}
              {!this.state.showTransactionDetailDialog ? null :
                <TransactionDetailDialogComponent standAloneProps={LayoutService.getContainedDialogProps(false)}
                  inputAction={CrudAction.CREATE} modalAgNode={{}}
                  open={this.state.showTransactionDetailDialog || false}
                  onClose={() => this.setState({ showTransactionDetailDialog: false })}
                  // clone stuff
                  onClone={null} // hides the clone icon
                  transactionToClone={this.state.transactionToClone}
                />
              }

              {/* Action Menu Items */}
              {this.state.openCommentFile && this.state.agCellData ?
                <AddEditCommentComponent
                  entityType={ENTITY_TYPE.TRANSACTION}
                  open={this.state.openCommentFile || false}
                  handleClose={(_reloadRequired) => {
                    this.setState({ openCommentFile: false });
                    if (_reloadRequired) {
                      this.fetchData(true);
                    }
                  }}
                  agCellData={this.state.agCellData}
                />
                : null}
              {this.state.openDeleteFile && this.state.agCellData ?
                <DeleteFileComponent
                  entityType={ENTITY_TYPE.TRANSACTION}
                  open={this.state.openDeleteFile || false}
                  handleClose={(isRefresh) => { this.setState({ openDeleteFile: false }); isRefresh && this.fetchData(true); }}
                  agCellData={this.state.agCellData}
                />
                : null}
              {(this.state.openRerunConfirm || this.state.openAddToRerunValidationQueueConfirm) && this.state.agCellData ?
                <RerunValidationComponent
                  reRunFileValidation={this.state.openRerunConfirm}
                  entityType={ENTITY_TYPE.TRANSACTION}
                  open={this.state.openRerunConfirm || this.state.openAddToRerunValidationQueueConfirm}
                  handleClose={() => this.setState({ openRerunConfirm: false, openAddToRerunValidationQueueConfirm: false })}
                  agCellData={this.state.agCellData}
                />
                : null}
               {( this.state.openAddToRerunForUnmatchedQueueConfirm) && this.state.agCellData ?
                <RerunUnmatchQueueComponent
                  reRunUnmatch={this.state.openRerunUnmatchConfirm}
                  entityType={ENTITY_TYPE.TRANSACTION}
                  open={this.state.openAddToRerunForUnmatchedQueueConfirm}
                  handleClose={() => this.setState({ openAddToRerunForUnmatchedQueueConfirm: false })}
                  agCellData={this.state.agCellData}
                />
                : null}

                 {/* Confirm New Profile dialog */}
              {!this.state.showConfirmCreateBatchProfileDialog ? null :
                <Dialog open={this.state.showConfirmCreateBatchProfileDialog || false} fullWidth={true} maxWidth='md' scroll={false ? "paper" : "body"}>
                  {/* Title */}
                  <DialogTitle style={{ padding: 0 }} id="dialog-title">
                    <AppBar position="static">
                      <Toolbar>
                        <Typography variant="h6" className={classes.root}>Confirm Batch Profile Creation</Typography>
                        {LayoutService.getIconButton(this.state.isCreatingBatchProfile, MatIconService.OK, "Confirm", () => {
                          this.createBatchProfile();
                        }, "inherit", "keyActionSave")}
                        {LayoutService.getIconButton(this.state.isCreatingBatchProfile, MatIconService.CANCEL, "Cancel", () => { this.setState({ showConfirmCreateBatchProfileDialog: false }) }, "secondary", "keyActionCancel1")}
                      </Toolbar>
                      {this.state.isCreatingBatchProfile ? <LinearProgress color="secondary" /> : null}
                    </AppBar>
                  </DialogTitle>
                  {/* Content */}
                  <DialogContent style={{ padding: 0 }}>
                    <Typography style={{ width: "100%", paddingLeft: 8, paddingRight: 8, backgroundColor: "#ecc802", color: "#681501" }} variant="h6" align="center">
                      {this.state.isCreatingBatchProfile ? "Creating Batch Profiles..." : "This Action cannot be reversed."}
                    </Typography>
                    <Divider style={{ width: "100%" }} />
                    <Grid container direction="column" justifyContent="center" alignItems="stretch">
                      {/* <h6>Please select a Profile Source</h6> */}
                      <FormControl required style={{ marginTop: 24, marginInline: 24 }}>
                        <InputLabel >Profile Source</InputLabel>
                        <Select disabled={this.state.isCreatingBatchProfile} value={this.state.selProfileSourceId || ''}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          onChange={(e) => this.setState({ selProfileSourceId: e.target.value })}>
                          {(this.state.profileSourceList || []).map(
                            (el, index) => { return <MenuItem value={el.sourceId}>{el.sourceName}</MenuItem> }
                          )}
                        </Select>
                        {/* <FormHelperText>The selected Profile Source will be used for each Profile.</FormHelperText> */}
                      </FormControl>
                      <Divider style={{ marginTop: 24 }} />
                      <Grid style={{ paddingInline: 24, paddingTop: 24, paddingBottom: 32, backgroundColor: MatThemeService.getAlternatingBG() }}>
                        <h6>New Profile Records will be created, or matched, for the Unmatched Transactions in this File, when one of the below conditions is true.</h6>
                        <h6>Note: For the Profile to be inserted, the Transaction MUST have a value for the "COUNTRY" field AND a value for at least one of the following fields: "US NPI NUMBER"; or "US LICENSE STATE"; or "US STATE LICENSE NUMBER"; or "US TAX ID NUMBER"; or "RECIPIENT IDENTIFIER VALUE"</h6>
                       
                      </Grid>
                    </Grid>
                  </DialogContent>
                </Dialog>
              }


              <TransactionSearchDialogComponent
                open={this.state.showTransactionSearchDialog || false}
                onClose={() => this.setState({ showTransactionSearchDialog: false })}
              />
            </div>
          );

        case ResultStatus.ERROR:
        default:
          return (<PageErrorComponent label="Error Loading Transaction Details" classes={classes} onRetry={() => { this.fetchData(true); }} />);
      }
    }
  }
}
export default LayoutService.getHocComponenet(transactionCenterComponent);